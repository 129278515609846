





import { Component, Vue, Prop } from 'vue-property-decorator'
import * as OM from '@/model';

@Component
export default class MiniSpinner extends Vue {

}

