







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CircleCard extends Vue {

    @Prop() label: string;

}
