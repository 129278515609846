









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import router from '@/router';

@Component
export default class StarsRisk extends Vue {
    
    @Prop({
        default: 0
    }) activeProp: number;

    stars: Array<any> = [1, 2, 3, 4, 5, 6, 7];

}

