








import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import IconTitleTextCard from '@/components/iconTitleTextCard.vue';
import { StorageServices } from '@/services/StorageServices'
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        IconTitleTextCard
    }
})
export default class MicroClassesView extends Vue {

    microCategories: string[] = [];
    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    
    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">
            Main Asset Class
        </small><p class="mb-0">` + to.params.macroClass + `</p>`;

        let filterVm = new OM.AssetFilterVm();
        filterVm.assetType = to.params.assetType;
        filterVm.macroClass = to.params.macroClass;
        filterVm.challengeIdentifier = to.params.challengeIdentifier;
        AssetClient.getAssetClasses(filterVm)
        .then(data => {
            next((vc: MicroClassesView) => {
                vc.microCategories = data;
                vc.filterVm = filterVm;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }
    
    select(cat: string){
        this.$router.push(this.$route.params.macroClass + '/' + cat)
    }
}

