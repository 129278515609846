














































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import CallToButton from '@/components/callToButton.vue';

@Component({
    components: {
        AssetTypeIcon,
        CallToButton
    }
})
export default class SwitchDetailModal extends Vue {
    
    @Prop({
        type: [Array]
    }) ordini: VM.OrdinePendingVm[];

    isPending(ordine: VM.OrdinePendingVm){
        return new Date(ordine.dataMovimento) > new Date(store.state.systemDate);
    }

}
