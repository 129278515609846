


















import router from '@/router';
import { Component, Vue } from 'vue-property-decorator';
import Chat from '@/components/chat.vue';
import store from '@/store';
import { ChatClient } from '@/services/Services';

@Component({
    components: {
        Chat
    }
})
export default class ChatRoom extends Vue {

    loaded: boolean = false;
    chatIdentifier: string = null;
    created(){
        ChatClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        .then(x => {
            this.chatIdentifier = x;
            this.loaded = true;
        })
    }

    startChat(){
        ChatClient.startChat(store.state.loginResponse.userIdentifier)
        .then(x => {
            this.chatIdentifier = x;
        })
    }
}

