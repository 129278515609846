


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class HelperModal extends Vue {
    @Prop() dictionaryKey: string;

    closeModal(){
        this.$emit('close');
    }
}
