














































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import CallToButton from '@/components/callToButton.vue';
import { GlobalConfigClient } from '@/services/Services';

@Component({
    components: {
        CallToButton
    }
})
export default class RankingFilterModal extends Vue {

    @Prop({
        type: [Object, VM.RankingFilterVm]
    }) vm: VM.RankingFilterVm;

    rollbackVm: VM.RankingFilterVm = null;

    infoConfig: OM.QuestionarioInizialeConfig = null;
    
    regioni: string[] = [];
    province: string[] = [];
    comuni: string[] = [];

    created(){
        this.rollbackVm = JSON.parse(JSON.stringify(this.vm));
        let proms = [];
        proms.push(GlobalConfigClient.getUserInfoConfig());
        proms.push(GlobalConfigClient.getRegioni());
        if(this.vm.areaGeo1)
            proms.push(GlobalConfigClient.getProvince(this.vm.areaGeo1));
        if(this.vm.areaGeo2)
            proms.push(GlobalConfigClient.getComuni(this.vm.areaGeo2));

        Promise.all(proms)
        .then(xs => {
            this.infoConfig = xs[0];
            this.regioni = xs[1];
            this.province = xs[2];
            this.comuni = xs[3];
        })
    }
    
    getProvince(){
        this.vm.areaGeo2 = '';
        this.vm.areaGeo3 = '';
        GlobalConfigClient.getProvince(this.vm.areaGeo1)
        .then(x => {
            this.province = x;
        })
    }
    
    getComuni(){
        this.vm.areaGeo3 = '';
        GlobalConfigClient.getComuni(this.vm.areaGeo2)
        .then(x => {
            this.comuni = x;
        })
    }

    rollback(){
        this.vm.periodo = this.rollbackVm.periodo;
        this.vm.fasciaDiEta = this.rollbackVm.fasciaDiEta;
        this.vm.statoLavorativo = this.rollbackVm.statoLavorativo;
        this.vm.conoscenzaFinanziaria = this.rollbackVm.conoscenzaFinanziaria;
        this.vm.areaGeo1 = this.rollbackVm.areaGeo1;
        this.vm.areaGeo2 = this.rollbackVm.areaGeo2;
        this.vm.areaGeo3 = this.rollbackVm.areaGeo3;
        this.$emit('close');
    }

    closeModal(){
        this.$emit('close');
    }
}
