import router from "@/router";
import store from "@/store";
import { UserClient } from "../Services";

export function RegisterPush(callback){
    if(!window.cordova)
        return;

    FirebasePlugin.hasPermission(function(hasPermission){
        if(hasPermission)
            RegisterPushNotification(callback);
        else
            FirebasePlugin.grantPermission(function(hasPermission){

                if(hasPermission)
                    RegisterPushNotification(callback);
            })
    });
    // FirebasePlugin.setUserId(userIdentifier);
}

export function UnregisterPush(){
    if(!window.cordova)
        return;
    
    FirebasePlugin.setAutoInitEnabled(false, function(){
        FirebasePlugin.unregister();
    });
}

function RegisterPushNotification(callback){

    FirebasePlugin.getToken(function(fcmToken) {
        UserClient.setFcmId(fcmToken);
    }, function(error) {
        console.log("FIREBASE PLUGIN GET TOKEN ERROR");
        console.log(error)
    });

    // FirebasePlugin.onTokenRefresh(function(fcmToken) {
    //     UserClientWS.setFCMID(fcmToken)
    // }, function(error) {
    //     console.log("FIREBASE PLUGIN ON TOKEN REFRESH ERROR");
    //     console.error(error);
    // });
    
    FirebasePlugin.onMessageReceived(callback);
};

function goToRoute(message: any) : void {
    if(message && message.route){
        var route = message.route;
        router.push(route)
    }
}