



















































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import { validateEmail } from '../../utility';
import { appVersion } from '@/config';

@Component
export default class Register extends Vue {

    vm: VM.RegisterVm = new VM.RegisterVm();
    pageState = '';

    created(){
        this.vm.appVersion = appVersion;
    }

    registrati(){
        if(!this.canSubmit)
            return;

        AuthServices.Register(this.vm).then( x => {
            this.pageState = x;
        }).catch( () => {
            this.pageState = 'error';
        })
    }

    get canSubmit(){
        return this.vm.nome && this.vm.cognome && validateEmail(this.vm.email) && this.vm.password;
    }

    retry(){
        this.pageState = '';
    }

    // addToSocialLogin(){
    //     if(!window.cordova)
    //         return;
    //     if(window.facebookConnectPlugin)
    //     {
    //         store.commit("showSpinner");
    //         window.facebookConnectPlugin.login(
    //             ['email','public_profile'], 
    //             (success: any) =>{
    //                 AuthServices.facebookLogin(success.authResponse.accessToken, this.vm.password)
    //                 .then(x => {
    //                     store.commit("hideSpinner");   
    //                     AuthServices.setAuthToken(x.token);
    //                     store.state.loginResponse = x;
    //                     StorageServices.setLoginResponse(x);
    //                     router.replace("/blank");
    //                 })
    //                 .catch(x => {
    //                     store.commit("hideSpinner");   
    //                 })
    //             }, 
    //             (error:any) =>{
    //                 store.commit("hideSpinner");   
    //                 console.dir(error);
    //             }
    //         );
    //     }
    // }

    resetPassword(){
        AuthServices.forgotPassword(this.vm.email)
        .then(x => {
            this.pageState = 'reset_mail_sent';
        })
        .catch(x => {
            this.pageState = 'error';
        })
    }

}
