













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import Chartist from 'chartist/dist/chartist.min.js';
import { removeDiacritics } from '@/utility';

@Component
export default class DonutChartCard extends Vue {

    @Prop({
        type: [ Array ]
    }) values: any[];

    @Prop({
        type: [ Array ]
    }) types: VM.TypeForDonutChartVm[];

    @Prop({
        type: String
    }) yType: string;

    @Prop({
        type: [ String ]
    }) label: string;

    chartId: string = 'c' + (Math.random() * 10000000).toFixed(0);

    chartElement: any;
    chartist: any;
    localValues: any[];

    @Watch('values')
    onValues(next, prev){
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    mounted(){
        this.chartElement = this.$refs.chartElement;
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    plotChart(){
        this.chartElement.style.width = "100%";
        var data = {
            series: this.localValues.map((x, index) => {
                return {
                    value: x,
                    className: removeDiacritics(this.types[index].nome).toLowerCase().replace(/ /g,"-")
                }
            }),
        };
        var options = {
            donut: true,
            donutWidth: 33,
            donutSolid: true,
            startAngle: 0,
            showLabel: true,
            labelInterpolationFnc: function(value) {
                return Math.round(value) + '%';
            }
        }
        this.chartist = new Chartist.Pie('#' + this.chartId, data, options);
    }
}

