import * as firebase from "firebase/app";
import "firebase/analytics";
import { Route } from 'vue-router';

//Per il debug di analytics
    //DOCUMENTAZIONE
    //https://firebase.google.com/docs/analytics/debugview
    //PAGINA DI DEBUG SU FIREBASE
    //https://console.firebase.google.com/project/_/analytics/debugview
    //ANDROID
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG (SOSTITUIRE PACKAGE_NAME)
    //adb shell setprop debug.firebase.analytics.app package_name     
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO
    //adb shell setprop debug.firebase.analytics.app .none.
    //IOS
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    //-FIRDebugEnabled 
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    // -FIRDebugDisabled
//-------------------------------------------------------

//Firebase configuration object
//https://firebase.google.com/docs/analytics/get-started?platform=web
var platform = "unset";
var firebaseConfig = null;

//Init firebase
//Chiamare al deviceready
//L'inizializzazione serve per l'auth(sia web che app) o per l'analytics WEB non per analytics APP
export function initFirebase(){
    setConfigAndPlatform();

    if(!firebaseConfig)
        return;

    (<any>window).firebase = firebase;
    // // Initialize Firebase
    firebase.default.initializeApp(firebaseConfig);
}

function setConfigAndPlatform(){
    if(!window.cordova || !device)
        return;

    if(device.platform == "iOS"){
        firebaseConfig = {
            apiKey: "AIzaSyCa5xUZlhKxXEaDl6FVFCiwgbgh6YLfnhg",
            authDomain: "capitaleyes-38c82.firebaseapp.com",
            //databaseURL: "https://capitaleyes-38c82.firebaseio.com",
            projectId: "capitaleyes-38c82",
            //storageBucket: "capitaleyes-38c82.appspot.com",
            appId: "1:427993613707:ios:671912ebbcf11383",
        };
        platform = "iOS";
    }
    else {
        firebaseConfig = {
            apiKey: "AIzaSyCetAp-RtGXE6jmOpKNRniZS9Jv1lDR2ms",
            authDomain: "capitaleyes-38c82.firebaseapp.com",
            //databaseURL: "https://capitaleyes-38c82.firebaseio.com",
            projectId: "capitaleyes-38c82",
            //storageBucket: "capitaleyes-38c82.appspot.com",
            appId: "1:427993613707:web:72dc48aada3dc434f45b8e",
        }
        platform = "android";
    }
}

//Send analytics
export function sendAnalytics(route: Route){
    if(!window.cordova){
        if(!firebaseConfig)
            initFirebase();
    
        if(!firebaseConfig)
            return;

        sendAnalyticsWeb(route);
    }
    else
        sendAnalyticsApp(route);
}

function sendAnalyticsWeb(route: Route){
    firebase.default.analytics().setCurrentScreen(route.name);
    firebase.default.analytics().logEvent("page_view", {
        page_location : route.path,
        page_title : route.name,
        page_path: route.fullPath
    });
    (<any>firebase.default.analytics().logEvent)("screen_view", {
        app_name: "[DEFAULT]",
        screen_name: route.name,
    });
}

function sendAnalyticsApp(route: Route){
    if(!window.cordova.plugins || !window.cordova.plugins.firebase)
        return;
    // window.cordova.plugins.firebase.analytics.logEvent(route.name)
    window.cordova.plugins.firebase.analytics.logEvent("page_view", {
        page_location : route.path,
        page_title : route.name,
        page_path: route.fullPath,
        app_name: "app.capitaleyes.com",
        platform: platform
    });
    // window.cordova.plugins.firebase.analytics.logEvent("screen_view", {
    //     app_name: "KPet",
    //     screen_name: route.name,
    // });
}