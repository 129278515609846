






























import router from '@/router';
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import RankingCard from '@/components/rankingCard.vue';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import RankingFilterModal from './rankingFilterModal.vue';
import { FriendChallengeClient } from '@/services/Services';

@Component({
    components: {
        RankingCard
    }
})
export default class FriendChallengeRanking extends Vue {

    people: VM.RankingCardVm[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        let friendChallenge = StorageServices.getSelectedFriendChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + friendChallenge.name + `</p>`;
        FriendChallengeClient.getFriendChallengeRankings(friendChallenge.identifier)
        .then(x => {
            next((vc: FriendChallengeRanking) => {
                vc.people = x;
                if(friendChallenge.creatorIdentifier == store.state.loginResponse.userIdentifier){
                    store.state.headerButtons.third = {
                        imgUrl: './img/menu_setup.svg',
                        onClick: () => {
                            vc.$router.push('/challenge/' + vc.$route.params.challengeIdentifier + '/' + 
                            vc.$route.params.portafoglioIdentifier + '/editFriendChallenge/' + 
                            vc.$route.params.friendChallengeIdentifier)
                        }
                    }
                }
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        store.state.headerButtons.third = null;
        next();
    }

}

