











import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import WalletBar from '@/components/walletBar.vue'

@Component({
    components: {
        WalletBar
    }
})
export default class AssetsLayoutView extends Vue {
}

