







































import { Vue, Component, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import StarsRisk from '@/components/starsRisk.vue';
import { ChallengeClient } from '@/services/Services';

@Component({
    components: {
        StarsRisk
    }
})
export default class ChallengeCard extends Vue {

    @Prop({
        type: [Object, VM.ChallengeVm]
    }) challenge: VM.ChallengeVm;

    players: number = null;

    created(){
        ChallengeClient.countPlayersInChallenge(this.challenge.identifier)
        .then(x => {
            this.players = x;
        })
    }
}
