



















import { Vue, Prop, Component } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class OverlayQuestionario extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop() icon: string;

    clicked(ev){
        this.$emit('click', ev);
    }

}

