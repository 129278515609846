








































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import firebase from "firebase/app";
import "firebase/auth";
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import forgotPasswordModal from '@/components/forgotPasswordModal.vue';
import { appVersion } from '@/config';
import * as VM from '@/viewModel';
import SocialLogin from './socialLogin.vue';
import { GlobalConfigClient } from '@/services/Services';

@Component({
    components: {
        SocialLogin
    }
})
export default class Login extends Vue {

    loginError: string = "";
    isCordova: boolean = false;

    created(){
        if(Object.keys(store.state.dizionario).length == 0){
            GlobalConfigClient.getDizionario()
            .then(x => {
                store.state.dizionario = x;
            });
        }
        if(window.cordova)
            this.isCordova = true;
        else
            this.isCordova = false;
    }

    fbUrl: string = 'https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=page&client_id=437127953782598&display=popup&redirect_uri=' 
        + fbRedirectPage + '/fbToken.html&scope=email';
	facebookLogin(){
        if(window.cordova){
            
            if(window.facebookConnectPlugin)
            {
                store.commit("showSpinner");
                window.facebookConnectPlugin.login(
                    ['email','public_profile'], 
                    (success: any) =>{
                        store.commit("hideSpinner");   
                        console.dir(success);
                        store.commit("showSpinner");
                        let vm: VM.ProviderTokenLoginVm = {
                            access_token: success.authResponse.accessToken,
                            password: '',
                            appVersion: appVersion
                        }
                        AuthServices.facebookTokenLogin(vm)
                        .then(x => {
                            store.commit("hideSpinner");   
                            AuthServices.setAuthToken(x.token);
                            store.state.loginResponse = x;
                            StorageServices.setLoginResponse(x);
                            router.replace("/blank");
                        })
                        //AuthServices.facebookLogin(success.authResponse.accessToken).then(x => {
                    }, 
                    (error:any) =>{
                        store.commit("hideSpinner");   
                        console.dir(error);
                    }
                );
            } 
        } else {
            window.location.href = this.fbUrl;
        }
    }

    gplusLogin(){
        if(!window.cordova)
            return;

        let googleProvider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithRedirect(googleProvider)
        .then(function() {
            return firebase.auth().getRedirectResult();
        })
        .then(function(result: any) {
            var token = result.credential.idToken;

            let vm: VM.ProviderTokenLoginVm = {
                access_token: token,
                password: '',
                appVersion: appVersion
            }
            AuthServices.googleTokenLogin(vm)
            .then(x => {
                store.commit("hideSpinner");
                AuthServices.setAuthToken(x.token);
                store.state.loginResponse = x;
                StorageServices.setLoginResponse(x);
                router.replace("/blank");
            })

        }).catch(function(error) {
            console.log(error)
        });

    }



    socialLogin(token: string, provider: string){
        let vm: VM.ProviderTokenLoginVm = {
            access_token: token,
            password: '',
            appVersion: appVersion
        }
        let prom = null;
        if(provider == 'facebook'){
            prom = AuthServices.facebookTokenLogin(vm)
        } else if(provider == 'google') {
            prom = AuthServices.googleTokenLogin(vm)
        } else if(provider == "apple"){
            prom = AuthServices.appleTokenLogin(token)
        }
        prom.then(x => {
            store.commit("hideSpinner");   
            AuthServices.setAuthToken(x.token);
            store.state.loginResponse = x;
            StorageServices.setLoginResponse(x);
            router.replace("/blank");
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    register(){
        this.$router.push('register');
    }

    login(){
        this.$router.push('loginForm');
    }
    
    openBrowser(link){
        window.open(link, '_blank');
    }

    forgotPassword(){
        ModalServices.OpenModal(forgotPasswordModal,{});
    }

}
