


















import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { ModalServices } from '@/services/ModalServices';
import SwiperSlides from '@/components/swiperSlides.vue';
import ChallengeCard from '@/components/challengeCard.vue';
import CallToButton from '@/components/callToButton.vue';
import ConfirmModal from '@/components/confirmModal.vue';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import InputModal from '@/components/inputModal.vue';
import AlertModal from '../../../components/alertModal.vue';
import { ChallengeClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        SwiperSlides,
        ChallengeCard,
        CallToButton
    }
})
export default class ChallengeDetailView extends Vue {

    challenge: OM.Challenge = null;
    
    get canPartecipa(){
        return this.$route.params.identifier && this.challenge && 
            new Date(this.challenge.dataFine).getTime() > new Date().getTime();
    }

    beforeRouteEnter(to, from, next){
        let prom = null;
        if(to.params.identifier){
            prom = ChallengeClient.getById(to.params.identifier)
        } else if (to.params.challengeIdentifier){
            prom = new Promise((resolve, reject) => {
                resolve(StorageServices.getSelectedChallenge());
            });
        }
        prom.then( data => {
            next((vc: ChallengeDetailView) => {
                vc.challenge = data;
            });
        })
    }

    startChallenge(){
        if(this.challenge.password){
            ModalServices.OpenModal(InputModal, {
                text: this.$store.state.dizionario.challenge_password_required,
                callback: (input: string) => {
                    if(input == this.challenge.password){
                        this.createPortafoglio();
                    } else {
                        ModalServices.OpenModal(AlertModal, {
                            text: this.$store.state.dizionario.challenge_password_errata_text,
                            callback: () => {}
                        }, {
                            width: '80%',
                            height: 'auto',
                            transition: 'modalUp',
                        })
                    }
                },
            }, {
                width: '80%',
                height: 'auto',
                transition: 'modalUp',
            })
        } else {
            this.createPortafoglio();
        }
    }

    createPortafoglio(){
        
        let modalCallBack = () => {
            let vm = new OM.CreateUserBalanceVm();
            vm.userIdentifier = store.state.loginResponse.userIdentifier;
            vm.challengeIdentifier = this.challenge.identifier;
            vm.nomeGara = this.challenge.titolo;
            vm.balance = 100000;
            vm.fromDate = new Date().toISOString();
            PortafoglioClient.createNewBalance(vm)
            .then(x => {
                let challenge = StorageServices.getSelectedChallenge();
                challenge.portafoglioIdentifier = x;
                StorageServices.setSelectedChallenge(challenge);
                this.$router.replace('/challenge/' + this.$route.params.identifier + '/' + x +  '/assets');
            })
        }
        if(new Date(this.challenge.dataInizio).getTime() <= Date.now()){
            ModalServices.OpenModal(ConfirmModal, {
                title: this.$store.state.dizionario.crea_portafoglio_modal_title,
                text: this.$store.state.dizionario.crea_portafoglio_modal_text,
                callback: modalCallBack
            })
        } else {
            modalCallBack();
        }
    }

}
