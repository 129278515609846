


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class AlertModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;
    
    @Prop({
        required: true
    }) callback: (userInput: string) => void;

    userInput: string = "";
    doCb(){
        this.callback(this.userInput);
        this.$emit('close');
    }
    
}
