

























































































































































import router from '@/router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';
import { PushServices } from '@/services/PushServices';
import { ModalServices } from '../../services/ModalServices';
import HelperModal from '@/components/helperModal.vue';
import HeaderButton from './headerButton.vue';
import { StorageServices } from '../../services/StorageServices';
import { privacyPolicyUrl, termsAndConditionUrl } from '@/config'
import { ArchivioClient, AssetClient, ChatClient, GlobalConfigClient } from '@/services/Services';

@Component({
    components: {
        HeaderButton
    }
})
export default class LayoutView extends Vue {

    openMenu: boolean = true;
    layoutContainer: any = null;

    bodyScroll: number = 0;
    @Watch('openMenu')
    onOpenMenu(next, prev){
        if(this.openMenu) {
            this.bodyScroll = document.body.scrollTop;
            setTimeout(() => {
                this.layoutContainer.style="overflow-y: hidden;position: relative; height: 100vh;";
            }, 300);
        } else {
            this.layoutContainer.style="";
            setTimeout(() => {
                document.body.scrollTop = this.bodyScroll;
            }, 10)
        }
    }

    get showBack(){ 
        return this.$route.meta.backButton && !this.openMenu;
    }
    get showGlossario(){
        return this.$route.path.indexOf('cinture') > -1 && !this.openMenu;
    }
    get showFilterButton(){
        return this.$route.meta.filterButton && !this.openMenu;
    }
    get headerText() {
        return store.state.headerText || `Capital<span class="subtitle">EYES</span>`
    }
    get layoutKey() {
        return this.$route.meta.pageKey || 'vueview';
    }
    get hideMenu() {
        return !!this.$route.meta.hideMenu;
    }
    
    openBrowser(what){
        if(what == 'privacy')
            window.open(privacyPolicyUrl, '_blank');
        else if(what == 'terms')
            window.open(termsAndConditionUrl, '_blank');
    }

    mounted(){
        this.layoutContainer = this.$refs.layoutContainer;
        this.layoutContainer.style="overflow-y: hidden;position: relative; height: 100vh;";

        router.afterEach((to, from) => {
            if(to.name == 'blank')
                this.openMenu = true;
        });

        AssetClient.getValuteValues().then(x => {
            store.state.valute.EURUSD = x['EURUSD'];
            store.state.valute.USD = x['EURUSD'];
            store.state.valute.EURGBP = x['EURGBP'];
            store.state.valute.GBP = x['EURGBP'];
            store.state.valute.EURCHF = x['EURCHF'];
            store.state.valute.CHF = x['EURCHF'];
            store.state.valute.EURJPY = x['EURJPY'];
            store.state.valute.JPY = x['EURJPY'];
            console.log(store.state.valute);
        })
        
        GlobalConfigClient.getDizionario()
        .then(x => {
            store.state.dizionario = x;
        });
        
        GlobalConfigClient.getSystemDate()
        .then(x => {
            store.state.systemDate = x;
        });

        GlobalConfigClient.getDizionarioHtml()
        .then(x => {
            store.state.dizionarioHtml = x;
        });

        ChatClient.checkNewMessages(store.state.loginResponse.userIdentifier, StorageServices.getLastChatTimestamp())
        .then(x => {
            store.state.counters.chat = x;
        })
        
        ArchivioClient.checkToRead(store.state.loginResponse.userIdentifier)
        .then(x => {
            store.state.counters.archivio = x;
        })
        
        let notificationCallback = (payload: any) => {

            console.log(payload);
            if(payload.additionalData.redirect == '/chatRoom'){
                if(this.$route.name == 'chatRoom')
                    store.state.notification = null;
                else
                    store.state.counters.chat++;
            }
            if(!payload.tap){// || !payload.additionalData.foreground){
                if(payload.additionalData.coldstart){
                    if(payload.additionalData.redirect){
                        this.openMenu = false;
                        this.$router.push(payload.additionalData.redirect);
                    }
                }
            } else {
                store.state.notification = payload;
                let route = '';
                if(payload.additionalData.redirect){
                    route = payload.additionalData.redirect;
                }
                store.state.notificationFunction = () => {
                    if(route){
                        this.openMenu = false;
                        this.$router.push(route);
                    }
                }
            }
        };
                        
        document.addEventListener("deviceready", () => {
            PushServices.Register(notificationCallback);
        }, false);
    }

    notificationDrawerClick(){
        store.state.notification = null;
        store.state.notificationFunction();
    }

    openGlossario(){
        if(!this.showGlossario)
            return;
        
        this.$router.push('/glossario')
    }

    get showHelp(){
        return !!store.state.dizionarioHtml[this.$route.meta.tutorial] && !this.openMenu;
    }

    openHelp(){
        if(!this.showHelp)
            return;

        ModalServices.OpenModal(HelperModal,
        {
            dictionaryKey: this.$route.meta.tutorial
        });
    }
}

