













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';

@Component
export default class Domanda extends Vue {
    @Prop({
        type: [Object, OM.Domanda]
    }) domanda: OM.Domanda;
    @Prop() value: OM.RispostaAlQuestionario;

    select(item: OM.Risposta){
        let risposta: OM.RispostaAlQuestionario = {
            testoDomanda: this.domanda.testo,
            testoRisposta: item.testo,
            valore: item.valore,
            testoRiepilogo: item.testoRiepilogo
        }
        this.$emit('input', risposta);
    }

}

