














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { DomandaQualitaConfigClient, RispostaQualitaClient } from '@/services/Services';

@Component
export default class DomandeQualitaModal extends Vue {

    domande: OM.DomandaQualitaConfig[] = [];
    rispostaQualita: OM.RispostaQualitaVm = new OM.RispostaQualitaVm();
    selectedRisposte: number[] = [];

    showCommentoSection: boolean = false;

    @Prop() titoloLezione: string;

    created(){
        this.rispostaQualita.userIdentifier = store.state.loginResponse.userIdentifier;
        this.rispostaQualita.userName = store.state.loginResponse.nomeUtente;
        this.rispostaQualita.nomeTema = StorageServices.getTemaVm().nome;
        this.rispostaQualita.nomeCintura = StorageServices.getSelectedCintura().nome;
        this.rispostaQualita.risposte = [];
        this.rispostaQualita.lezioneIdentifier = this.$route.params.lezioneIdentifier;
        this.rispostaQualita.titoloLezione = this.titoloLezione;
        DomandaQualitaConfigClient.getAll()
        .then(x => {
            this.domande = x;
        })
    }

    setRisposta(domandaIndex, rispostaText, rispostaIndex){
        this.rispostaQualita.risposte[domandaIndex] = {
            domanda: this.domande[domandaIndex].testoDomanda,
            risposta: rispostaText
        };
        Vue.set(this.selectedRisposte, domandaIndex, rispostaIndex); 
    }

    saveRisposte(){
        RispostaQualitaClient.save(this.rispostaQualita)
        .then(x => {
            this.rispostaQualita.identifier = x;
            this.showCommentoSection = true;
        })
    }
    
    saveCommento(){
        RispostaQualitaClient.save(this.rispostaQualita)
        .then(x => {
            this.$emit('close');
        })
    }
}
