

















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class ArchivioNode extends Vue {

    @Prop({
        type: [VM.ArchivioNodeVm, Object]
    }) node: VM.ArchivioNodeVm;

    emit(eventType: string){
        this.$emit(eventType, this.node)
    }

}
