



import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeDiacritics } from '@/utility';

@Component
export default class AssetTypeIcon extends Vue {
    @Prop() label: string;

    get imgName(){
        let ris = removeDiacritics(this.label).toLowerCase();
        return ris.replace(/ /g,"_");
    }
}
