







































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import MiniSpinner from '@/components/miniSpinner.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        MiniSpinner
    }
})
export default class PeriodiHorizontalTable extends Vue {

    datas: VM.AssetPerformanceByMonthVm[] = null;

    beforeRouteEnter(to, from, next){
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.label + `</small>`;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        if(window.cordova)
            (<any>screen).orientation.lock('portrait');
        next();
    }

    mounted(){
        AssetClient.getPerformanceTableOfBenchmark(this.$route.params.assetIdentifier)
        .then(x => {
            this.datas = x;
        })
        if(window.cordova)
            (<any>screen).orientation.lock('landscape');
    }

}
