


























import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import store from '@/store';
import { fbRedirectPage } from '@/config';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import { appVersion } from '@/config';

@Component
export default class LoginForm extends Vue {

    vm: VM.LoginDataWithVersion = new VM.LoginDataWithVersion();
    loginError: boolean = false;

    created(){
        this.vm.appVersion = appVersion;
    }

    back(){
        this.$router.back();
    }

    login(){
        this.loginError = false;

        AuthServices.LoginWithVersion(this.vm).then( x => {
            AuthServices.setAuthToken(x.token);
            store.state.loginResponse = x;
            StorageServices.setLoginResponse(x);
            router.replace("/blank");
        }).catch( () =>{
            this.loginError = true;
        })
    }

}
