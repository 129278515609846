
























import { Component, Vue, Prop } from 'vue-property-decorator';
import router from '@/router';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class accordionItem extends Vue {
    
    @Prop() temiList: OM.Tema[];
    @Prop() lezioniList: OM.Lezione[];
    @Prop() index: number;
    
    created(){

        

    }

    navToLezione(lezioneId: string){
        this.$router.push('/cinture/lezione/' + lezioneId);
    }

}
