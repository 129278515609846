










































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import router from '@/router';
import PageHeader from '@/components/pageHeader.vue';
import Accordion from '@/components/accordion.vue';
import AccordionItem from '@/components/accordionItem.vue';
import TemaCintura from '@/components/temaCintura.vue'
import ProgressBar from '@/components/progressBar.vue'
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient, LezioneClient, ProgressiUserClient, TemaClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        Accordion,
        AccordionItem,
        TemaCintura,
        ProgressBar
    }
})
export default class DettaglioTemaView extends Vue {

    lezioniList: VM.LezioneGroupedVm[] = [];
    selectedCintura: OM.CinturaWithTemiVm = new OM.CinturaWithTemiVm();
    temaVm: OM.TemaVm = new OM.TemaVm();
    progressiUser: OM.ProgressiUser = new OM.ProgressiUser();
    cintureList: OM.CinturaWithTemiVm[] = [];
    loaded: boolean = false;

    beforeRouteEnter(to, from, next){
        let lezioniList;
        let progressiUser: OM.ProgressiUser;
        let prom1 = LezioneClient.getGroupedByCinturaByPercorso(to.params.temaIdentifier, to.params.percorsoIdentifier);
        let prom2 = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier);
        let cinturaValue = to.params.cinturaIdentifier ? to.params.cinturaIdentifier : null;
        let prom3 = TemaClient.getTemaVmByPercorso(to.params.temaIdentifier, cinturaValue, to.params.percorsoIdentifier);
        let prom4 = CinturaClient.getCintureWithTemiByPercorso(to.params.temaIdentifier)
        Promise.all([prom1, prom2, prom3, prom4])
        .then(datas => {
            lezioniList = datas[0];
            progressiUser = datas[1];
            lezioniList.forEach(x => Vue.set(x, 'isOpen', true));
            // let cinturaApertaInd = lezioniList.findIndex(x => x.elements[0].cinturaIdentifier == to.params.cinturaIdentifier);
            // if(cinturaApertaInd > -1){
            //     Vue.set(lezioniList[cinturaApertaInd], 'isOpen', true);
            // }
            StorageServices.setCintureList(datas[3]);
            lezioniList.forEach(item => {
                item.elements.forEach(lezione => {
                    Vue.set(lezione, 'superata', progressiUser.lezioniSuperate.findIndex(x => x.identifier == lezione.identifier) > -1);
                });
            });

            next((vc: DettaglioTemaView) => {
                vc.cintureList = StorageServices.getCintureList();
                vc.selectedCintura = vc.cintureList.find(x => x.identifier == to.params.cinturaIdentifier) || null;
                vc.lezioniList = lezioniList;
                vc.progressiUser = progressiUser;
                vc.temaVm = datas[2];
                StorageServices.setTemaVm(vc.temaVm);
                vc.loaded = true;

                vc.$nextTick(() => {
                    let el: any = vc.$refs.upperSection;
                    let lowerHeight = window.innerHeight - parseFloat(getComputedStyle(el).height) - 55;
                    (<HTMLElement>vc.$refs.lowerSection).style.height = lowerHeight + "px";
                })
            });
        })
    }

    navToLezione(lezione: VM.LezioneGroupedVm, cinturaEnabled: boolean){
        if(!cinturaEnabled)
            return;

        let cintura = this.cintureList.filter(x => x.identifier == lezione.cinturaIdentifier)[0];
        StorageServices.setSelectedCintura(cintura);
        cintura.temi.forEach(x => {
            if(x.nome == this.temaVm.nome){
                StorageServices.setTemaVm(x);
            }
        })
        this.$router.push('/cinture/' + lezione.cinturaIdentifier + '/lezione/' + lezione.identifier)
    }

    toggleOpen(item: VM.LezioneGroupedVm){
        item.isOpen = !item.isOpen;
    }

}

