



















































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';

@Component
export default class PeriodiHorizontalTable extends Vue {

    datiPeriodo: OM.DatiPeriodo[] = null;
    headers = ["Periodo","N° Periodi","Perf. Max","Perf. Media","Perf. Min","Periodi Positivi","Periodi Negativi"];

    beforeRouteEnter(to, from, next){
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.label + `</small>`;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        if(window.cordova)
            (<any>screen).orientation.lock('portrait');
        next();
    }

    mounted(){
        this.datiPeriodo = StorageServices.getHorizontalTableData();
        if(window.cordova)
            (<any>screen).orientation.lock('landscape');
    }

}
