













































import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import DomandeQualitaModal from './domandeQualitaModal.vue';
import CallToButton from '@/components/callToButton.vue';
import OverlayQuestionario from './overlayQuestionario.vue';

@Component({
    components: {
        TemaCintura,
        ProgressBar,
        CallToButton,
        OverlayQuestionario
    }
})
export default class RisultatoQuestionarioView extends Vue {

    lezione: OM.Lezione = new OM.Lezione();
    result: VM.SaveQuestionarioResponseVm = new VM.SaveQuestionarioResponseVm();
    domandaIndex: number = 0;
    selectedCintura: VM.CinturaWithTemiVm = new VM.CinturaWithTemiVm();
    temaVm: OM.TemaVm = new OM.TemaVm();
    progressCintura: number = 0;
    progressTema: number = 0;
    animateProgress: boolean = false;
    animateTema: boolean = false;
    showOverlay: boolean = true;

    showCinturaSection: boolean = false;
    showTemaSection: boolean = false;
    showRisposteSection: boolean = false;

    overlayTitle: string = "";
    overlayText: string = "";
    overlayIcon: string = "";
    
    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = (<any>store.state.dizionario).questionario_risultato_headerText;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    created(){
        this.lezione = StorageServices.getSelectedLezione();
        this.result = StorageServices.getRisultatoQuestionario();
        console.log(this.result);
        if(this.result.newCintura){
            this.overlayTitle = "Nuova cintura";
            this.overlayText = "Hai sbloccato una nuova cintura";
            this.overlayIcon = "New_cintura";
        } else if(this.result.lezioneSuperata){
            this.overlayTitle = "Congratulazioni";
            this.overlayText = "Test superato";
            this.overlayIcon = "Congrats";
        } else {
            this.overlayTitle = "Test non superato";
            this.overlayText = "Leggi i consigli e ripeti la prova";
            this.overlayIcon = "Test";
        }

        this.selectedCintura = StorageServices.getSelectedCintura();
        this.temaVm = StorageServices.getTemaVm();
        this.progressCintura = this.result.cinturaProgressFrom;
        this.progressTema = this.result.temaProgressFrom;
        let cintureList = StorageServices.getCintureList();
        cintureList.forEach(x => {
            if(x.identifier == this.selectedCintura.identifier){
                x.progress = this.result.cinturaProgressTo;
                x.temi.forEach(x => {
                    if(x.identifier == this.temaVm.identifier){
                        x.completamento = this.result.temaProgressTo;
                    }
                })
            }
        })
        StorageServices.setCintureList(cintureList);
    }
    startAnimations(){
        setTimeout(() => {
            this.progressCintura = this.result.cinturaProgressTo;
            this.animateProgress = true;
        }, 100);

        setTimeout(() => this.showCinturaSection = true);
        setTimeout(() => {
            this.showTemaSection = true;
            setTimeout(() => {
                this.animateTema = true;
                this.progressTema = this.result.temaProgressTo;
            }, 500);
        }, 2000);
        setTimeout(() => this.showRisposteSection = true, 5000);
    }

    hideOverlayAndStart(){
        this.showOverlay = false;
        if(this.result.newCintura || this.result.lezioneSuperata){
            this.startAnimations();
        } else {
            this.showCinturaSection = true;
            this.showTemaSection = true;
            this.showRisposteSection = true;
        }
    }

    showQuestionarioQualita(){
        let prom = new Promise<void>((resolve, reject) => {
            ModalServices.OpenModal(DomandeQualitaModal,
            {
                titoloLezione: this.lezione.titolo
            }, () => {
                resolve();
            });
        });
        return prom;
    }

    goBack(){
        this.showQuestionarioQualita()
        .then(x => {
            this.$router.go(-3);
        })

        // RispostaQualitaServices.CheckRispostaQualita(this.lezione.identifier, store.state.loginResponse.userIdentifier)
        // .then(x => {
        //     if(!x){
        //         this.showQuestionarioQualita()
        //         .then(x => {
        //             this.$router.go(-3);
        //         })
        //     } else {
        //         this.$router.go(-3);
        //     }
        // })
    }
}

