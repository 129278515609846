













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import router from '@/router';
import * as OM from '@/model';

@Component
export default class Accordion extends Vue {
    @Prop() open: boolean;
    @Prop({
        default: false
    }) indent: boolean;

    @Prop() active: boolean;

    openThis: boolean = false;
    created(){
        if(this.open){
            this.openThis = true;
        }
    }

    toggleOpen(){
        this.openThis = !this.openThis;
        this.$emit('toggleOpen', this.openThis);
    }
}

