



























import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import router from '@/router';
import { StorageServices } from '@/services/StorageServices'
import store from '@/store';
import * as VM from '@/viewModel';
import * as OM from '@/model';
import RankingCardSelector from '@/components/rankingCardSelector.vue';
import { FriendChallengeClient, RankingCardClient } from '@/services/Services';

@Component({
    components: {
        RankingCardSelector
    }
})
export default class CreateFriendChallenge extends Vue {

    cards: VM.RankingCardVm[] = [];
    vm: VM.CreateFriendChallengeVm = new VM.CreateFriendChallengeVm();

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + 'Gare con gli amici' + `</p>`;
        RankingCardClient.getRankings(challenge.identifier, 'iniziogara')
        .then(x => {
            next((vc: CreateFriendChallenge) => {
                vc.vm.challengeIdentifier = challenge.identifier;
                vc.vm.challengeName = challenge.titolo;
                vc.cards = x;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    save(){
        FriendChallengeClient.createFriendChallenge(this.vm)
        .then(x => {
            this.$router.back();
        })
    }

}

