










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import AnimatedNumber from '@/components/animatedNumber.vue';
import store from '@/store';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        AnimatedNumber
    }
})
export default class WalletBar extends Vue {

    @Prop({
        type: Number,
        default: 10
    }) matchRisk: number;

    @Prop({
        type: Number,
        required: false
    }) walletRisk: number;
    
    @Prop({
        type: Number,
        required: false,
        default: 0
    }) sumToLiquidity: number;
    
    @Prop({
        type: Number,
        required: false,
        default: null
    }) liquidityPercent: number;
    
    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) refresh: boolean;
    
    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) forSwitch: boolean;
    
    @Watch('walletRisk')
    onWalletRiskChange(next, prev){
        store.state.portafoglioStatus.walletRisk = next;
    }

    get percWalletRisk(){
        return store.state.portafoglioStatus.walletRisk * 100;
    }

    get liquidityPercentPerc(){
        if(this.liquidityPercent || this.liquidityPercent != null)
            return this.liquidityPercent * 100;
        else
            return store.state.portafoglioStatus.liquidityPercentage * 100;
    }
    
    get totalLiquidity(){
        return store.state.portafoglioStatus.liquidity + this.sumToLiquidity;
    }

    created(){
        if(!this.$route.params.portafoglioIdentifier){
            console.error('Cannot use walletBar component on this route, missing param "portafoglioIdentifier"');
            return;
        }
        if(this.refresh)
            StorageServices.deletePortafoglioStatus(this.$route.params.portafoglioIdentifier);
            
        let prom = new Promise<OM.PortafoglioStatusVm>((resolve, reject) => {
            let wallet = StorageServices.getPortafoglioStatus(this.$route.params.portafoglioIdentifier);
            if(wallet){
                resolve(wallet);
            } else {
                PortafoglioClient.getPortafoglioStatusWithSwitch({
                    portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                    creaSwitchVm: null
                })
                .then(x => {
                    StorageServices.setPortafoglioStatus(x);
                    resolve(x);
                })
            }
        });
        prom.then(x => {
            store.state.portafoglioStatus = x;
            //this.wallet = store.state.portafoglioStatus;
            // this.wallet.portafoglioIdentifier = x.portafoglioIdentifier;
            // this.wallet.liquidity = x.liquidity;
            // this.wallet.liquidityPercentage = x.liquidityPercentage;
            // this.wallet.walletRisk = x.walletRisk;
            // this.wallet.matchRisk = x.matchRisk;
            if(this.walletRisk){
                store.state.portafoglioStatus.walletRisk = this.walletRisk;
            }
        })
    }



}
