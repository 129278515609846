















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';

@Component
export default class SwiperPercentage extends Vue {
    @Prop() value: number;
    @Prop() assetType: string;

    slideWidth: number;
    minTranslate: number;
    maxTranslate: number;
    step: number;

    swiper: any;
    internalValue: number = 0;
    mounted(){
        let comp = this;
        function transFunction(){
            comp.internalValue = (this.translate - comp.minTranslate) / (comp.step);
            if(comp.internalValue < 0 || isNaN(comp.internalValue))
                comp.internalValue = 0;
            if(comp.internalValue > 100)
                comp.internalValue = 100;
            comp.internalValue = Math.round(comp.internalValue * 10) / 10;
            comp.$emit('input', comp.internalValue);
        }
        this.swiper = new Swiper(this.$refs.swiperContainer, {
            direction: 'horizontal',
            slidesPerView: 10,
            speed: 100,
            freeMode: true,
            freeModeMomentumBounce: false,
            centeredSlides: true,
            slideToClickedSlide: true,
            on: {
                transitionEnd: transFunction,
                sliderMove: transFunction
            },
        });
        this.slideWidth = this.swiper.width;
        this.minTranslate = this.swiper.translate;
        this.maxTranslate = -(this.slideWidth * 10 - this.minTranslate);
        this.step = (this.maxTranslate - this.minTranslate) / 100;
    }

    @Watch('value')
    onValueChange(next, prev){
        let setTo = this.minTranslate + next * this.step;
        this.swiper.setTranslate(setTo);
    }

}

