import { render, staticRenderFns } from "./createFriendChallenge.vue?vue&type=template&id=459fb0e2&scoped=true&"
import script from "./createFriendChallenge.vue?vue&type=script&lang=ts&"
export * from "./createFriendChallenge.vue?vue&type=script&lang=ts&"
import style0 from "./createFriendChallenge.vue?vue&type=style&index=0&id=459fb0e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459fb0e2",
  null
  
)

export default component.exports