


























import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
import { UserClient } from '@/services/Services';

@Component
export default class ConfirmModal extends Vue {

    hoLetto: boolean = false;

    denyFunction(){
        this.$router.back();
        this.$emit('close');
    }
    
    confirmFunction(){
        if(!this.hoLetto)
            return;
        UserClient.acceptPlayDisclaimer(store.state.loginResponse.userIdentifier);
        this.$emit('close');
    }
}
