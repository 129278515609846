




















import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient } from '@/services/Services';

@Component({
    components: {
        TemaCintura,
        ProgressBar
    }
})
export default class CintureView extends Vue {

    username: string = store.state.loginResponse.nomeUtente;
    
    cintureList: OM.CinturaWithTemiVm[] = [];

    beforeRouteEnter(to, from, next){
        CinturaClient.getCintureWithTemiByPercorso(to.params.percorsoIdentifier)
        .then(x => {
            next((vc: CintureView) => {
                vc.cintureList = x;
                StorageServices.setCintureList(vc.cintureList);
            })
        })
    }

    navTo(cintura: VM.CinturaWithTemiVm, tema: OM.Tema){
        if(cintura.enabled){
            this.$router.push('/percorsi/' + this.$route.params.percorsoIdentifier + '/cinture/' + cintura.identifier + '/temi/' + tema.identifier);
        }
    }

    toggleCintura(){
        this.cintureList.forEach(cintura => {
            cintura.enabled = true;
        });
    }

}

