















































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import router from '@/router';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import AudioPlayer from '@/components/audioPlayer.vue';
import { LezioneClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent,
        TemaCintura,
        ProgressBar,
        AudioPlayer
    }
})
export default class DettaglioLezioneView extends Vue {

    lezione: OM.Lezione = new OM.Lezione();
    loaded: boolean = false;
    temaVm: OM.TemaVm = new OM.TemaVm();
    selectedCintura: VM.CinturaWithTemiVm = new VM.CinturaWithTemiVm();
    showPlayer: boolean = false;
    media: any;

    classChangeDimension: string = "dimension_1";
    changeDimensionStepNumber: number = 1;

    beforeRouteEnter(to, from, next){
        LezioneClient.getById(to.params.lezioneIdentifier)
        .then(x => {
            next((vc: DettaglioLezioneView) => {
                vc.lezione = x;
                vc.temaVm = StorageServices.getTemaVm();
                vc.selectedCintura = StorageServices.getSelectedCintura();
                vc.loaded = true;
            });
        });
    }

    navBack(){
        this.$router.back();
    }

    minusText(){
        if(this.changeDimensionStepNumber > 1) {
            this.changeDimensionStepNumber -= 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }

    plusText(){
        if(this.changeDimensionStepNumber < 4) {
            this.changeDimensionStepNumber += 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }

    navToTest(){
        StorageServices.setSelectedLezione(this.lezione);
        router.push('/cinture/' + this.$route.params.cinturaIdentifier + '/lezione/' + 
            this.$route.params.lezioneIdentifier + '/test')
    }

    startPolly(){
        this.showPlayer = true;

        // this.media = new Media(this.lezione.pollyUrl, () => {
        // });
        // this.media.play();
    }

}

