






























































































































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import * as OM from '@/model';
import DonutChartCard from '@/components/donutChartCard.vue'
import { StorageServices } from '@/services/StorageServices'
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import { ModalServices } from '@/services/ModalServices';
import SellAssetModal from '@/components/sellAssetModal.vue';
import SwitchDetailModal from './switchDetailModal.vue';
import SwitchDeleteModal from './switchDeleteModal.vue';
import DeleteOrderModal from './deleteOrderModal.vue';
import WalletBar from '@/components/walletBar.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        DonutChartCard,
        AssetTypeSlugs,
        WalletBar,
        AssetTypeIcon
    }
})
export default class WalletView extends Vue {

    types: string[] = []
    filter: OM.AssetFilterVm = new OM.AssetFilterVm();
    assets: OM.AssetForDonutChart[] = [];
    ordiniPending: OM.OrdinePendingVm[] = [];
    switches: OM.SwitchPendingVm[] = [];
    donutSerie: number[] = [];
    donutTypes: OM.TypeForDonutChartVm[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<p class="mb-0">` + challenge.titolo + `</p>`;

        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = to.params.portafoglioIdentifier;
        Promise.all([
            PortafoglioClient.getAssetClasses(filterVm),
            PortafoglioClient.getDonutChart(filterVm),
            PortafoglioClient.getDonutChartTypes(filterVm),
            OrdineClient.getOrdiniPendingWithSwitch(filterVm.portafoglioIdentifier, "")
        ])
        .then(datas => {
            next((vc: WalletView) => {
                datas[0].unshift('All');
                vc.types = datas[0];
                filterVm.assetType = "All";
                vc.filter = filterVm;
                vc.assets = datas[1];
                vc.donutTypes = datas[2];
                vc.donutSerie = datas[2].map(x => x.peso);
                vc.ordiniPending = datas[3].ordini;
                vc.switches = datas[3].switches;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }
    
    filterByType(val){
        this.filter.assetType = val;
        let proms = []
        Promise.all([
            PortafoglioClient.getDonutChart(this.filter),
            OrdineClient.getOrdiniPendingWithSwitch(this.filter.portafoglioIdentifier, val)
        ])
        .then(x => {
            this.assets = x[0];
            this.ordiniPending = x[1].ordini;
            this.switches = x[1].switches;
        })
    }

    get assetsWithControvalore(){
        return this.assets.filter(x => x.controvalore > 0);
    }

    slideAsset(item){
        if(item.nome == "Liquidity")
            return;
        Vue.set(item, 'selected', !item.selected);
    }

    reinit(){
        let filterVm = new OM.AssetFilterVm();
        filterVm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        Promise.all([
            PortafoglioClient.getAssetClasses(filterVm),
            PortafoglioClient.getDonutChart(filterVm),
            PortafoglioClient.getDonutChartTypes(filterVm),
            OrdineClient.getOrdiniPendingWithSwitch(filterVm.portafoglioIdentifier, '')
        ])
        .then( datas => {
            datas[0].unshift('All');
            this.types = datas[0];
            filterVm.assetType = "All";
            this.filter = filterVm;
            this.assets = datas[1];
            this.donutTypes = datas[2];
            this.donutSerie = datas[2].map(x => x.peso);
            this.ordiniPending = datas[3].ordini;
            this.switches = datas[3].switches;
        })
    }

    totalControvalore(item: OM.OrdinePendingVm[]){
        return item.reduce((t, x) => t += x.controvalore, 0);
    }

    deleteOrder(item: OM.OrdinePendingVm, index){
        ModalServices.OpenModal(DeleteOrderModal,
        {
            ordine: item,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier
        }, this.reinit);
    }
    
    deleteSwitch(item: OM.SwitchPendingVm, index){
        ModalServices.OpenModal(SwitchDeleteModal,
        {
            ordini: item.orders
        }, this.reinit);
    }

    openSellAssetModal(asset: OM.AssetForDonutChart) {
        if(asset.nome == "Liquidity")
            return;
        ModalServices.OpenModal(SellAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            saveCb: (vm: OM.CreaOrdineVm) => {
                OrdineClient.creaOrdine(vm)
                .then(x => {
                    PortafoglioClient.getPortafoglioStatusWithSwitch({
                        portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                        creaSwitchVm: null
                    })
                    .then(x => {
                        store.state.portafoglioStatus = x;
                        StorageServices.setPortafoglioStatus(x);
                    });
                    this.reinit();
                    ModalServices.CloseLastModal();
                })
            }
        });
    }

    openSwitchDetail(ordini){
        ModalServices.OpenModal(SwitchDetailModal,
        {
            ordini: ordini
        });
    }

    goToSwitch(){
        this.$router.push('switchVendita');
    }

}

