import { render, staticRenderFns } from "./macroClasses.vue?vue&type=template&id=158d5d84&scoped=true&"
import script from "./macroClasses.vue?vue&type=script&lang=ts&"
export * from "./macroClasses.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158d5d84",
  null
  
)

export default component.exports