










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AssetTypeSlugs extends Vue {

    @Prop() slugs: string[];
    selectedIndex: number = 0;

    select(item, index){
        this.selectedIndex = index;
        this.$emit('select', item);
    }
}
