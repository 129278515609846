
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class AlertModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;

    @Prop({
        required: false
    }) type: string;
    
}
