

































import { Component, Vue, Prop } from 'vue-property-decorator';
import { fbRedirectPage } from '@/config';
import store from '@/store';
import AlertModal from '@/components/alertModal.vue';
import firebase from "firebase/app";
import "firebase/auth";

@Component
export default class SocialLogin extends Vue {

    @Prop() loginCb: any;
    @Prop() facebookCb: any;
    @Prop() googleCb: any;

    ios: boolean = false;

    apple: any = null;
    facebook: any = null;
    google: any = null;

    created(){
        this.google = new firebase.auth.GoogleAuthProvider();
        this.facebook = new firebase.auth.FacebookAuthProvider();
        this.apple = new firebase.auth.OAuthProvider('apple.com');
        // this.apple.addScope('email');
        // this.apple.addScope('name');

        if(!window.cordova){
            this.ios = false;
            return;
        }

        if(device.platform == "iOS" && parseInt(device.version) >= 13)
            this.ios = true;
        else
            this.ios = false;            
    }

    signIn(provider, name){
        let self = this;
        if(name == 'apple'){
            if(window.hasOwnProperty("cordova"))
                this.signInApp(provider, name);
            // else
            //     this.signInWeb(provider, name);
        } else {
            firebase.auth().signInWithRedirect(provider)
            .then(() => {
                console.log("getting redirect result");
                var result = firebase.auth().getRedirectResult()
                .then(x => {
                    var token = self.getToken(x.credential, name);
                    self.loginCb(token, name);
                });
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
            });
        }
    }

    signInApp(provider, name){
        var self = this;
        firebase.auth().signInWithRedirect(provider)
        .then(function() {
            return firebase.auth().getRedirectResult();
        })
        .then(function(result) {
            var token = self.getToken(result.credential, name);

            self.loginCb(token, name);
            
        }).catch(function(error) {
            console.log(error)
        });
    }

    getToken(credential, name): string {
        console.log("CREDENTIAL", credential, name)
        if(name == "facebook")
            return credential.accessToken
        else if(name == "google")
            return credential.idToken;
        else if(name == "apple")
            return credential.idToken;
    }
}
