
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component
export default class DeleteOrderModal extends Vue {
    
    @Prop({
        type: [Object, VM.OrdinePendingVm]
    }) ordine: VM.OrdinePendingVm;
    
    @Prop({
        type: String,
        required: true
    }) portafoglioIdentifier: string;
    
    deleteOrder(){
        OrdineClient.delete(this.ordine.identifier)
        .then(x => {
            PortafoglioClient.getPortafoglioStatusWithSwitch({
                portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                creaSwitchVm: null
            })
            .then(x => {
                store.state.portafoglioStatus = x;
                StorageServices.setPortafoglioStatus(x);
            });
            this.$emit('close');
        });
    }

}
