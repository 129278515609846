import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/css/style.css'
import '@/css/poppins.css'
// import 'chartist/dist/chartist.min.css';
import '@/filters';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';


// import VModal from 'vue-js-modal';
// Vue.use(VModal, { dynamic: true });

import OpModal from '@/plugins/modalPlugin'
Vue.use(OpModal);

import '@/directives';

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//     store.state.showSpinner++;
//     next();
// });


import { CommonServices } from '@/services/CommonServices';

CommonServices.unauthorizedCb = () => {
    router.push('/?redirect=' + router.currentRoute.fullPath );
}
CommonServices.showSpinnerCb = () => {
    store.commit("showSpinner");
}
CommonServices.hideSpinnerCb = () => {
    store.commit("hideSpinner");   
}

import { initFirebase } from './services/FirebaseServices';

if(!window.cordova){
    drFunction();
}
document.addEventListener("deviceready", () => {
    drFunction();
});
function drFunction(){
    initFirebase();
    if(!window.cordova)
        return;
        
	if(device.platform == "iOS"){
        delegate(document, "click", "a.linkified", function(ev) {
            window.open(ev.target.href, '_blank');
        });
    }
    Keyboard.hideFormAccessoryBar(false);
    window.addEventListener('keyboardWillShow', (event) => {
        document.body.classList.add('keyboardopen');
    });
    window.addEventListener('keyboardWillHide', () => {
        document.body.classList.remove('keyboardopen');
    });
    if(Keyboard && Keyboard.hideFormAccessoryBar){
        Keyboard.hideFormAccessoryBar(false);
    }
    // codePushUpdateFunction();
}


router.beforeEach((to, from, next) => {        
    // sendAnalytics(to);
    next();
})

function delegate(el, evt, sel, handler) {
    el.addEventListener(evt, function(event) {
        var t = event.target;
        while (t && t !== this) {
            if (t.matches(sel)) {
                handler.call(t, event);
            }
            t = t.parentNode;
        }
    });
}


// document.addEventListener("resume", function () {
//     codePushUpdateFunction();
// });
// function codePushUpdateFunction(){
//     //codePush.checkForUpdate();
//     codePush.sync(function(status) {
//         switch (status) {
//             case SyncStatus.DOWNLOADING_PACKAGE:
//                 store.state.notification = { title: "AGGIORNAMENTO", message: "Download in corso"}
//                 break;
//             case SyncStatus.INSTALLING_UPDATE:
//                 store.state.notification = { title: "AGGIORNAMENTO", message: "Installazione in corso"}
//                 break;
//         }
//     }, {
//         updateDialog: {
//             appendReleaseDescription: false,
//             descriptionPrefix: "",
//             optionalUpdateMessage: "E' disponibile una nuova versione dell'app, premi Aggiorna per proseguire!",
//             optionalInstallButtonLabel: "Aggiorna",
//             optionalIgnoreButtonLabel: "Rimanda",
//             updateTitle: "Aggiornamento disponibile"
//         },
//         installMode: InstallMode.IMMEDIATE
//      });
// }

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
