
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
// import RangeSlider from 'vue-range-slider';
// import InfiniteSlider from '@/components/infiniteSlider.vue';
import SwiperPercentage from '@/components/swiperPercentage.vue';
import WalletBar from '@/components/walletBar.vue';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import AnimatedNumber from '@/components/animatedNumber.vue';
import CallToButton from '@/components/callToButton.vue';
import 'vue-range-slider/dist/vue-range-slider.css';
import store from '@/store';
import * as OM from '@/model';
import * as Enum from '@/enum';
import { stdDev } from '@/utility'
import NumberInputModal from '@/components/numberInputModal.vue';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        SwiperPercentage,
        WalletBar,
        AnimatedNumber,
        CallToButton
    }
})
export default class SellAssetModal extends Vue {

    @Prop({
        type: [Object, VM.AssetForDonutChart]
    }) asset: VM.AssetForDonutChart;
    
    @Prop({
        type: String,
        required: true
    }) portafoglioIdentifier: string;

    @Prop({
        type: [VM.CreaSwitchVm, Object],
    }) creaSwitchVm: VM.CreaSwitchVm;

    @Prop({
        required: true
    }) saveCb: (vm: VM.CreaOrdineVm) => void;

    portafoglioStatus: OM.PortafoglioStatusVm = new OM.PortafoglioStatusVm();

    sliderValue: number = 0;
    minSlider: number = 0;
    maxSlider: number = 100;
    dataForCalcolo: VM.DataForCalcoloAcquistoVm = new VM.DataForCalcoloAcquistoVm();
    walletRisk: number = 0;
    valoreInEur: number = 0;
    tipoAsset: string = "";
    lastNav: number = 0;
    isVenditaInput: boolean = false;
    venditaInput: number = 0;
    percentualeAsset: number = 0;
    isQuantitaInput: boolean = false;
    quantitaInput: number = 0;
    isSliderInput: boolean = false;
    
    created(){
        this.portafoglioStatus = StorageServices.getPortafoglioStatus(this.portafoglioIdentifier);
        PortafoglioClient.getDataForCalcoloAcquistoWithSwitch({
            portafoglioIdentifier: this.portafoglioIdentifier, 
            assetIdentifier: this.asset.assetIdentifier,
            creaSwitchVm: this.creaSwitchVm ? this.creaSwitchVm : null
        })
        .then(x => {
            this.tipoAsset = x.tipoAsset;
            this.lastNav = x.lastNav;
            this.dataForCalcolo = x;
            this.valoreInEur = this.lastNav / store.state.valute[this.asset.valuta];
            this.percentualeAsset = this.asset.controvalore / this.portafoglioStatus.controvalorePortafoglio;
            this.calcolaWalletRisk(0);
        })
    }

    openInputModal(text, key, value){
        this.$opModal.show(NumberInputModal,
        {
            text: text,
            value: this[value],
            callback: (val) => { this[key] = parseFloat(val) }
        });
    }
    
    showVenditaInput() {
        this.isVenditaInput = !this.isVenditaInput;
        this.venditaInput = parseFloat(this.totaleVendita.toFixed(2));
        if (this.venditaInput == 0)
            this.venditaInput = null;
        if(this.isVenditaInput){
            setTimeout(() => {
                (<HTMLElement>this.$refs.venditaInput).focus();
            }, 100)
        }
    }

    get totaleOrdineSwitch(){
        if(!this.creaSwitchVm)
            return 0;

        let vendite = this.creaSwitchVm.ordiniVendita.reduce((p, x) => p += x.controvalore, 0);
        let acquisti = this.creaSwitchVm.ordiniAcquisto.reduce((p, x) => p += x.controvalore, 0);
        return vendite - acquisti;
    }

    get sumToLiquidity(){
        if(!this.creaSwitchVm){
            return this.totaleVendita;
        } else {
            return -store.state.portafoglioStatus.liquidity + this.totalLiquidity + this.totaleVendita;
        }
    }

    get totalLiquidity(){
        if(!this.creaSwitchVm){
            return store.state.portafoglioStatus.liquidity;
        } else {
            return this.totaleOrdineSwitch;
        }
    }

    get totaleVendita(){
        return this.quantitaOrdine * this.valoreInEur;
    }

    get liquidityPercent(){
        return (this.sumToLiquidity + store.state.portafoglioStatus.liquidity) / store.state.portafoglioStatus.controvalorePortafoglio;
    }
    
    @Watch('quantitaInput')
    onQuantitaInputChange(next, prev){
        if(next > this.asset.quantita)
            next = this.asset.quantita;
        if(next < 0)
            next = 0;
        this.sliderValue = next * 100 / this.asset.quantita;
    }
    
    showQuantitaInput() {
        this.isQuantitaInput = !this.isQuantitaInput;
        this.quantitaInput = parseFloat(this.quantitaOrdine.toFixed(6));
        if (this.quantitaInput == 0)
            this.quantitaInput = null;
        if(this.isQuantitaInput){
            setTimeout(() => {
                (<HTMLElement>this.$refs.quantitaInput).focus();
            }, 100)
        }
    }
    
    showSliderInput(){
        this.isSliderInput = !this.isSliderInput;
        if(this.isSliderInput){
            setTimeout(() => {
                (<HTMLElement>this.$refs.sliderInput).focus();
            }, 100)
        }
    }

    get quantitaOrdine(){
        if (this.tipoAsset.toLowerCase() == "fondo")
            return this.asset.quantita * (this.sliderValue / 100);
        else if (this.tipoAsset.toLowerCase() == "etf")
            return parseFloat(Math.floor(this.asset.quantita * (this.sliderValue / 100)).toFixed(0));
        return 0;
        // if (this.tipoAsset.toLowerCase() == "fondo")
        //     return this.totaleVendita / this.valoreInEur;
        // else if (this.tipoAsset.toLowerCase() == "etf")
        //     return Math.round(this.totaleVendita / this.valoreInEur);
        // return 0;
    }

    sliderTimeout: number;
    @Watch('sliderValue')
    onSliderValueChange(next, prev){
        if(!this.isSliderInput) {
            //next = Math.round(next);
        } else {
            if(next > 100){
                this.sliderValue = 100;
                next = 100;
            }
            if(next < 0){
                this.sliderValue = 0;
                next = '';
            }
        }
        this.sliderValue = next;
        clearTimeout(this.sliderTimeout);
        this.sliderTimeout = setTimeout(() => {
            this.calcolaWalletRisk(next);
        }, 50)
    }
    
    @Watch('venditaInput')
    onVenditaInputChange(next, prev){
        if(next > this.asset.controvalore)
            next = this.asset.controvalore;
        if(next < 0)
            next = 0;
        this.sliderValue = next * 100 / this.asset.controvalore;
    }

    calcolaWalletRisk(sliderValue){
        let variazioniConAsset = [];
        let finalAssetPercentage = this.percentualeAsset * (sliderValue / 100);
        this.dataForCalcolo.variazioniPesate.forEach((x, i) => {
            variazioniConAsset[i] = x - (finalAssetPercentage * this.dataForCalcolo.variazioniAsset[i]);
        });
        this.walletRisk = stdDev(variazioniConAsset) * Math.sqrt(250);
        return this.walletRisk;
    }

    beforeDestroy(){
        store.state.portafoglioStatus.walletRisk =  this.calcolaWalletRisk(0);
    }

    createOrdine(){
        let vm: VM.CreaOrdineVm = new VM.CreaOrdineVm();
        vm.userIdentifier = store.state.loginResponse.userIdentifier;
        vm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        vm.tipoOrdine = Enum.TipoOrdine.Vendita;
        vm.assetIdentifier = this.asset.assetIdentifier;
        vm.assetName = this.asset.nome;
        vm.assetType = this.asset.tipo;
        vm.controvalore = this.totaleVendita;
        vm.dataOrdine = new Date().toISOString();
        vm.percentage = this.sliderValue;
        this.saveCb(vm);
    }
    
}
