




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AudioPlayer extends Vue {

    @Prop() source: string;

    isPlaying: boolean = false;
    player: any;
    timeline: any;
    currentLine: any;
    currentTime: string = '00:00';
    endTime: string = '00:00';

    play(){
        this.isPlaying = true;
        this.player.play();
    }
    stop(){
        this.isPlaying = false;
        this.player.stop();
    }
    togglePlay(){
        this.isPlaying = !this.isPlaying;
        if(!this.isPlaying)
            this.player.pause();
        else 
            this.player.play();
    }

    mounted(){
        this.player = this.$refs.player;
        this.timeline = this.$refs.timeline;
        this.currentLine = this.$refs.currentLine;
    }

    setEndTime(e){
        this.endTime = this.formatTime(e.target.duration);
    }

    setTime(e) {
        var x;
        var setTime;
        var setWidth;
        var nowWidth;
        var timeLineWidth = this.timeline.offsetWidth;
        this.player.pause();

        if (e.touches[0].pageX) {
            x = e.touches[0].pageX;
        } else {
            x = e.touches[0].clientX;
        }
        nowWidth = x - this.timeline.getBoundingClientRect().left;
        setWidth = (nowWidth * 100) / timeLineWidth;

        this.currentLine.style.width = setWidth + '%';
        this.player.currentTime = (setWidth * this.player.duration) / 100;
        if(this.isPlaying)
            this.player.play();
    };
    
    updateTime() {
        if (this.isPlaying) {
            var time = this.player.currentTime;
            this.currentTime = this.formatTime(time);
            this.currentLine.style.width = (time * 100) / this.player.duration + '%';
        }
    };

    formatTime(time) {
        var timeArr: any = [
            Math.floor((((time % 31536000) % 86400) % 3600) / 60),
            ((((time % 31536000) % 86400) % 3600) % 60)
        ];

        timeArr[1] = Math.floor(timeArr[1]);

        for (var i = 0; i < timeArr.length; i++) {
            if (timeArr[i] < 10) {
                timeArr[i] = '0' + timeArr[i];
            };
        }
        return timeArr[0] + ':' + timeArr[1];
    }

}

