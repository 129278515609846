






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';

@Component
export default class PageHeader extends Vue {
    pageTitle: string = "";
    created(){
        this.pageTitle = this.$route.meta.title;
    }
}

