














import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component
export default class DeleteOrderModal extends Vue {
    
    @Prop({
        type: [Array]
    }) ordini: VM.OrdinePendingVm[];
    
    deleteSwitch(){
        let vm: VM.DeleteMultipleVm = {
            orders: this.ordini
        }
        OrdineClient.deleteMultiple(vm)
        .then(x => {
            PortafoglioClient.getPortafoglioStatusWithSwitch({
                portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                creaSwitchVm: null
            })
            .then(x => {
                store.state.portafoglioStatus = x;
                StorageServices.setPortafoglioStatus(x);
            });
            this.$emit('close');
        });
    }

}
