











import { Component, Vue } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import store from '@/store';
import { appVersion } from '@/config';
import NewVersionModal from '@/components/newVersionModal.vue';
import { AuthServices } from '@/services/AuthServices';
import { StorageServices } from '@/services/StorageServices';
import { GlobalConfigClient, TokenClient } from '@/services/Services';

@Component
export default class SplashView extends Vue {

    mounted(){
        let cb = () => {
            GlobalConfigClient.getDizionario()
            .then(x => {
                store.state.dizionario = x;
                
                AuthServices.CheckAppVersion(appVersion)
                .then(x => {
                    if(window.cordova)
                        (<any>screen).orientation.lock('portrait');
                    
                    TokenClient.checkLoginWithVersion(appVersion)
                    .then(x => {
                        AuthServices.setAuthToken(x.token);
                        store.state.loginResponse = x;
                        StorageServices.setLoginResponse(x);
                        this.$router.replace("/blank");
                    })
                    .catch(err => {
                        this.$router.replace('login');
                    });
                })
                .catch(err => {
                    if(err == 'new_version'){
                        this.openVersionModal();
                        return;
                    }
                })
            })
        }
        if(!window.cordova)
            cb()
        else
            document.addEventListener("deviceready", cb, false);
    }

    openVersionModal(){
        ModalServices.OpenModal(NewVersionModal, 
        {},
        () => {
            this.openVersionModal();
        })
    }

    created(){
        document.addEventListener("backbutton", () => {
            if(ModalServices.modalNames.length > 0){
                ModalServices.CloseLastModal(true);
                return;
            }
            // if(this.$route.path.indexOf("login") > -1){
            //     navigator.
            // }
            this.$router.back();
        }, false);

    }

}
