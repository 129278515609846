







import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FileInput extends Vue {

    @Prop() multiple: boolean;
    @Prop() inputId: string;
    @Prop() show: boolean;

    onFileSelect(ev){
        if(this.multiple){
            this.$emit('input', ev.target.files);
        } else {
            let file = ev.target.files[0];
            this.$emit('input', file);
        }
    }        
}

