















































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import store from '@/store';
import Component from '@/routeComponent';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { PortafoglioClient } from '@/services/Services';

@Component
export default class ChallengeLayoutView extends Vue {

    challenge: VM.ChallengeVm = null;
    challengeIdentifier: string = "";
    portafoglioIdentifier: string = "";

    isRankingEnabled: boolean = false;

    get hideBar(){
        return this.$route.meta.hideBar;
    }

    get pb0(){
        return this.$route.meta.pb0;
    }

    replaceTo(path){
        this.$router.replace(path);
    }

    created(){
        this.challenge = StorageServices.getSelectedChallenge();
        this.challengeIdentifier = this.$route.params.challengeIdentifier;
        this.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;

        this.isRankingEnabled = new Date(this.challenge.dataInizio).getTime() < Date.now();
        
        StorageServices.deletePortafoglioStatus(this.$route.params.portafoglioIdentifier);
        PortafoglioClient.getPortafoglioStatusWithSwitch({
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            creaSwitchVm: null
        })
        .then(x => {
            StorageServices.setPortafoglioStatus(x);
            store.state.portafoglioStatus = x;
        });
    }

    get isAnalysisDisabled(){
        return !store.state.portafoglioStatus.analysisEnabled;
    }

    get activePath(){
        if(this.$route.fullPath.indexOf('challenges') > -1)
            return  'challenges';
        else if(this.$route.fullPath.indexOf('ranking') > -1)
            return  'ranking';
        else if(this.$route.fullPath.indexOf('wallet') > -1)
            return  'wallet';
        else if(this.$route.fullPath.indexOf('assets') > -1)
            return  'assets';
        else if(this.$route.fullPath.indexOf('analysis') > -1)
            return  'analysis';
    }
    
}

