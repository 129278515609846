






















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import { beltColorToSvg } from '@/utility'

@Component
export default class RankingCard extends Vue {

    @Prop({
        type: [Object, VM.RankingCardVm]
    }) item: VM.RankingCardVm;

    @Prop() position: number;

    get beltPath(){
        return './img/' + beltColorToSvg(this.item.coloreCintura);
    }
}

