declare var FileUploadOptions: any;
declare var FileTransfer: any;

import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
import { StorageServices } from '@/services/StorageServices';

class _FileServices{

    UploadCordovaImage(fileUri: string, userIdentifier: string){
        var prom = new Promise<{ response: string }>((resolve, reject) => {
            var win = (r: any) => {
                resolve(r);
            }
            var fail = (error: any) => {
                reject(error);
            }
            var options = new FileUploadOptions();
            let now = new Date();
            options.fileKey = "file";
            let token = StorageServices.getLoginResponse().token;
            options.headers = {
                'Access-Control-Allow-Origin': '*',
                'authorization': 'Bearer ' + token
            };
            var ft = new FileTransfer();
            ft.upload(fileUri, encodeURI(baseUrl + "api/User/UpdateProfileImage"), win, fail, options);
        })
        return prom;
    }

    WriteToFile(fileName, obj) {
        let prom = new Promise<void>((resolve, reject) => {
            let data = JSON.stringify(obj);
            (<any>window).resolveLocalFileSystemURL(window.cordova.file.dataDirectory, function(directoryEntry) {
                directoryEntry.getFile(fileName, { create: true }, function(fileEntry) {
                    fileEntry.createWriter(function(fileWriter) {
                        fileWriter.onwriteend = function(e) {
                            resolve();
                        }
                        fileWriter.onerror = function(e) {
                            reject(e);
                        }
                        var blob = new Blob([data], { type: 'text/plain' })
                        fileWriter.write(blob)
                    }, function(err){
                        reject(err);
                    })
                }, function(err){
                    reject(err);
                })
            }, function(err){
                reject(err);
            })
        })
        return prom;
    }
    ReadFromFile<T = any>(fileName: string) {
        let prom = new Promise<T>((resolve, reject) => {
            let pathToFile = window.cordova.file.dataDirectory + fileName;
            (<any>window).resolveLocalFileSystemURL(pathToFile, function(fileEntry) {
                fileEntry.file(function(file) {
                    var reader = new FileReader()
                    reader.onloadend = function(e) {
                        resolve(JSON.parse(<string>this.result))
                    }
                    reader.readAsText(file)
                }, function(err){
                    reject(err);
                })
            }, function(err){
                reject(err);
            })
        });
        return prom;
    }
}
export let FileServices = new _FileServices();