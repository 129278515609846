

















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { GlobalConfigClient, UserClient } from '@/services/Services';

@Component
export default class InfoModal extends Vue {

    @Prop({
        type: [Object, VM.ProfileVm]
    }) vm: VM.ProfileVm;
    
    @Prop({
        type: Boolean,
        default: true
    }) showClose: boolean;

    infoConfig: OM.QuestionarioInizialeConfig = null;

    regioni: string[] = [];
    province: string[] = [];
    comuni: string[] = [];

    created(){
        let proms = [];
        proms.push(GlobalConfigClient.getUserInfoConfig());
        proms.push(GlobalConfigClient.getRegioni());
        if(this.vm.info.areaGeo1)
            proms.push(GlobalConfigClient.getProvince(this.vm.info.areaGeo1));
        if(this.vm.info.areaGeo2)
            proms.push(GlobalConfigClient.getComuni(this.vm.info.areaGeo2));

        Promise.all(proms)
        .then(xs => {
            this.infoConfig = xs[0];
            this.regioni = xs[1];
            this.province = xs[2];
            this.comuni = xs[3];
        })
    }

    getProvince(){
        this.vm.info.areaGeo2 = '';
        this.vm.info.areaGeo3 = '';
        GlobalConfigClient.getProvince(this.vm.info.areaGeo1)
        .then(x => {
            this.province = x;
        })
    }
    
    getComuni(){
        this.vm.info.areaGeo3 = '';
        GlobalConfigClient.getComuni(this.vm.info.areaGeo2)
        .then(x => {
            this.comuni = x;
        })
    }

    save(){
        UserClient.saveProfile(this.vm)
        .then(x => {
            UserClient.setQuestionarioIniziale(this.vm.identifier, true);
            this.$emit('close');
        })
    }

}
