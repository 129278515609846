





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AuthServices } from '../services/AuthServices';

@Component
export default class ForgotPasswordModal extends Vue {

    email: string = "";
    pageState: string = '';
    
    closeModal(){
        this.$emit('close');
    }

    sendResetPassword(){
        this.pageState = '';
        AuthServices.forgotPassword(this.email).then( () => {
            this.pageState = 'ok';
        }).catch( () => {
            this.pageState = 'error';
        })
    }
}
