












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import store from '@/store';
import ChartCard from '@/components/chartCard.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices'

@Component({
    components: {
        ChartCard
    }
})
export default class AsnalysisSlide extends Vue {

    @Prop({
        type: [Array]
    }) serie: OM.DateValue[];

    @Prop() title: string;

    @Prop() chartType: string;

    @Prop() filter: OM.AssetFilterVm;

    get yType(){
        return this.chartType == 'perc' ? '%' : this.chartType;
    }
    
    openChart(data, label, yType){
        label = label.replace('%', 'perc');
        StorageServices.setChartData(data);
        let title = "";
        if(this.filter.microClass != '')
            title = this.filter.microClass;
        else if(this.filter.macroClass != '')
            title = this.filter.macroClass;
        else if(this.filter.assetType != '')
            title = this.filter.assetType;
        else
            title = this.$store.state.dizionario.analysis_label_totale_portafoglio;
        this.$router.push('/horizontalChart/' + label + '/' + title + '/' + yType);
    }
}

