














import { Component, Vue, Prop } from 'vue-property-decorator';
import { playStoreUrl, appleStoreUrl } from '@/config';

@Component
export default class NewVersionModal extends Vue {
    
    openStore(){
        if(window.cordova){
            if(device.platform == "iOS"){
                window.open(appleStoreUrl, '_system');
            } else {
                window.open(playStoreUrl, '_system');
            }
        } else {
            window.open(playStoreUrl, '_blank');
        }
    }

}
