





























import { Vue, Prop, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as VM from '@/viewModel';
import AnimatedNumber from '@/components/animatedNumber.vue'

@Component({
    components: {
        AnimatedNumber
    }
})
export default class TemaCintura extends Vue {

    @Prop() cinturaColor: string;
    @Prop() nome: string;
    @Prop() icona: string;
    @Prop() index: number;
    @Prop() enabled: boolean;
    @Prop() completato: number;
    @Prop() lezioni: number;

    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) animate: boolean;
    
    @Watch('completato')
    onCompletedChange(next, prev){
        this.localCompleted = next;
    }

    localCompleted: number = 0;
    created(){
        if(this.animate){
            this.localCompleted = 0;
            setTimeout(() => {
                this.localCompleted = this.completato || 0;
            }, 0)
        } else {
            this.localCompleted = this.completato || 0;
        }
    }

    hexToCintura(hex: string){
        if(!hex)
            return;
        switch(hex.toLowerCase()){
            case "#F0F8FE".toLowerCase():
                return "Bianca";
            case "#FFD700".toLowerCase():
                return "Gialla";
            case "#FF8200".toLowerCase():
                return "Arancione";
            case "#31B700".toLowerCase():
                return "Verde";
            case "#0085CA".toLowerCase():
                return "Blu";
            case "#7C4D3A".toLowerCase():
                return "Marrone";
            case "#15001F".toLowerCase():
                return "Nera";
        }
    }
}

