





























































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import CircleCard from '@/components/circleCard.vue';
import { StorageServices } from '@/services/StorageServices'
import CallToButton from '@/components/callToButton.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import PeriodiCard from '@/components/periodiCard.vue';
import { ModalServices } from '@/services/ModalServices';
import BuyAssetModal from '@/components/buyAssetModal.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { AssetClient, OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        CallToButton,
        SwiperSlides,
        PeriodiCard,
        AssetTypeIcon
    }
})
export default class AssetsListView extends Vue {

    assets: OM.AssetForBuyListVm[] = [];
    benchmark: OM.AssetForBuyListVm = null;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.macroClass + `</small><p class="mb-0">` + to.params.microClass + `</p>`

        let filterVm = new OM.AssetFilterVm();
        filterVm.challengeIdentifier = to.params.challengeIdentifier;
        filterVm.assetType = to.params.assetType;
        filterVm.macroClass = to.params.macroClass;
        filterVm.microClass = to.params.microClass;
        AssetClient.getAssetsForBuyList(filterVm)
        .then( data => {
            next((vc: AssetsListView) => {
                let benchIndex = data.findIndex(x => x.tipoAsset.toLowerCase() == 'indice');
                if(benchIndex > -1)
                    vc.benchmark = data.splice(benchIndex, 1)[0];
                vc.assets = data;
            });
        })
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    openBuySellAssetModal(asset: OM.AssetForBuyListVm) {
        ModalServices.OpenModal(BuyAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            saveOrderCb: (vm: OM.CreaOrdineVm) => {
                OrdineClient.creaOrdine(vm)
                .then(x => {
                    PortafoglioClient.getPortafoglioStatusWithSwitch({
                        portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                        creaSwitchVm: null
                    })
                    .then(x => {
                        store.state.portafoglioStatus = x;
                        StorageServices.setPortafoglioStatus(x);
                        let challenge = StorageServices.getSelectedChallenge();
                        this.$router.push('/challenge/' + challenge.identifier + '/' + challenge.portafoglioIdentifier + '/wallet')
                    });
                })
            }
        });
    }

}

