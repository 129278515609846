



































































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import PageHeader from '@/components/pageHeader.vue';
import UserProgressBar from '@/components/userProgressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { CinturaClient, ProgressiUserClient, UserClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        UserProgressBar
    }
})
export default class PremiView extends Vue {
    loaded: boolean = false;
    user: VM.ProfileVm = new VM.ProfileVm();
    totalCinture: number = null;
    progressiUser: OM.ProgressiUser = new OM.ProgressiUser();
    currentCintura: VM.ProgressoCinturaVm = new VM.ProgressoCinturaVm();

    beforeRouteEnter(to, from, next){
        let proms = [];
        let promProgressi = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        proms.push(promProgressi);
        let promUser = UserClient.getProfile()
        proms.push(promUser);
        let promTotalCinture = CinturaClient.getTotalCinture();
        proms.push(promTotalCinture);
        Promise.all(proms).then( datas => {
            next((vc: PremiView) => {
                console.log(datas);
                vc.progressiUser = datas[0];
                vc.currentCintura = vc.progressiUser.progressiCinture[vc.progressiUser.progressiCinture.length - 1];
                vc.user = datas[1];
                vc.totalCinture = datas[2];
                vc.loaded = true;
            });
        })
    }

    clearProgressi(){
        ProgressiUserClient.clearProgressi();
        this.loaded = false;
        let proms = [];
        let promProgressi = ProgressiUserClient.getByUserIdentifier(store.state.loginResponse.userIdentifier)
        proms.push(promProgressi);
        let promUser = UserClient.getProfile()
        proms.push(promUser);
        let promTotalCinture = CinturaClient.getTotalCinture();
        proms.push(promTotalCinture);
        Promise.all(proms).then( datas => {
            this.progressiUser = datas[0];
            this.currentCintura = this.progressiUser.progressiCinture[this.progressiUser.progressiCinture.length - 1];
            this.user = datas[1];
            this.totalCinture = datas[2];
            this.loaded = true;
        })
    }

}

