




































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class PerformanceTable extends Vue {
    
    @Prop() table: VM.AssetPerformanceVm[];

    openTable(){
        this.$emit('openTable');
    }
    
}
