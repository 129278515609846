


























































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import ChallengeCard from '@/components/challengeCard.vue';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import DisclaimerModal from '@/components/disclaimerModal.vue';
import { ModalServices } from '@/services/ModalServices';
import SelectChallengeModal from './selectChallengeModal.vue';
import { ChallengeClient, FriendChallengeClient, PortafoglioClient, UserClient } from '@/services/Services';

@Component({
    components: {
        SwiperSlides,
        ChallengeCard
    }
})
export default class ChallengesView extends Vue {

    challenges: VM.ChallengeVm[] = [];
    ongoingChallenges: VM.ChallengeVm[] = [];
    attendedChallenges: VM.ChallengeVm[] = [];
    closedChallenges: VM.ChallengeVm[] = [];
    selectedSlide: number = 0;
    hasInviti: boolean = false;

    beforeRouteEnter(to, from, next){
        Promise.all([
            ChallengeClient.getAllVm(),
            PortafoglioClient.getUserChallenges(store.state.loginResponse.userIdentifier),
            UserClient.checkPlayDisclaimer(store.state.loginResponse.userIdentifier),
            FriendChallengeClient.getReceivedInvites(store.state.loginResponse.userIdentifier, "")
        ])
        .then( datas => {
            next((vc: ChallengesView) => {
                vc.challenges = datas[0];
                vc.challenges.forEach(x => {
                    let challengeIndex = datas[1].findIndex(y => y.identifier == x.identifier);
                    if(challengeIndex > -1){
                        x.portafoglioIdentifier = datas[1][challengeIndex].value;
                    }
                });
                vc.ongoingChallenges = vc.challenges.filter(x => {
                    //return new Date(x.dataInizio).getTime() < new Date().getTime() && new Date(x.dataFine).getTime() > new Date().getTime();
                    return new Date(x.dataFine).getTime() >= new Date().getTime();
                });
                vc.attendedChallenges = vc.challenges.filter(x => {
                    return x.portafoglioIdentifier && new Date(x.dataFine).getTime() >= new Date().getTime();
                });
                vc.closedChallenges = vc.challenges.filter(x => new Date(x.dataFine).getTime() < new Date().getTime());
                if(datas[3].length > 0){
                    vc.hasInviti = true;
                    vc.attendedChallenges.forEach(x => {
                        let challengeIndex = datas[3].findIndex(y => y.challengeIdentifier == x.identifier);
                        if(challengeIndex > -1){
                            x.notified = true;
                        }
                    })
                }

                //se non ha visionato il disclaimer, apre modale
                if(!datas[2]){
                    ModalServices.OpenModal(DisclaimerModal, {})
                }
            });
        })
    }

    onSlideChange(index){
        this.selectedSlide = index;
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.swiperSlidesContainer).scrollTop = 0;
        })
    }

    selectChallenge(item: VM.ChallengeVm){
        StorageServices.setSelectedChallenge(item);
        if(item.portafoglioIdentifier)
            this.$router.push('/challenge/' + item.identifier + '/' + item.portafoglioIdentifier + '/details')
        else
            this.$router.push('challenges/' + item.identifier)
    }

    goToAmici(){
        ModalServices.OpenModal(SelectChallengeModal, {
            challenges: this.attendedChallenges,
        })
    }

}
