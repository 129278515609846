











import { Vue, Component, Prop } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel'

@Component
export default class SelectChallengeModal extends Vue {

    @Prop() challenges: VM.ChallengeVm[];

    goTo(item: VM.ChallengeVm){
        StorageServices.setSelectedChallenge(item);
        this.$emit('close');
        this.$router.push('/challenge/' + item.identifier + '/' + item.portafoglioIdentifier + '/manageFriendChallenges')
    }

}
