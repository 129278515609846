










import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import moment from 'moment';
import Chartist from 'chartist/dist/chartist.min.js';

@Component
export default class ChartCard extends Vue {

    @Prop({
        type: String,
        default: 'month'
    }) xFrequency: string

    @Prop({
        type: [ Array ]
    }) values: any[];

    @Prop({
        type: String
    }) yType: string;

    @Prop({
        type: [ String ]
    }) label: string;

    chartId: string = 'c' + (Math.random() * 10000000).toFixed(0);

    chartElement: any;
    chartist: any;
    localValues: any[];

    @Watch('values')
    onValues(next, prev){
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    mounted(){
        this.chartElement = this.$refs.chartElement;
        this.localValues = JSON.parse(JSON.stringify(this.values));
        this.plotChart();
    }

    plotChart(){
        //this.localValues.splice(this.localValues.length - 1, 1);
        this.localValues.forEach(x => {
            //x.date = x.date.split('T')[0].substring(2);
            x.date = <any>new Date(x.date);
            if(this.yType == '%')
                x.value = x.value * 100;
        });
        this.chartElement.style.width = "100%";// this.chartItems.length * this.chartWidth + 'vw';
        //let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dic"];
        let months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
        let lastYear = null;
        let lastMonth = null;
        let daysCounter = 0;
        // let labels = this.localValues.map(x => {
        //     return x.date;
        // });

        var data = {
            //labels: labels,
            series: [
                {
                    data: this.localValues.map(x => {
                        return {
                            y: x.value,
                            x: x.date
                        }
                    })
                }
            ]
        };
        var options = {
            lineSmooth: Chartist.Interpolation.none({
                fillHoles: false
            }),
            showPoint: false,
            showArea: true,
            axisY: {
                labelInterpolationFnc: (value) => {
                    if(this.yType == '%'){
                        return value.toFixed(1) + '%';
                    } else {
                        if(value < 1000)
                            return value;
                        return (value / 1000).toFixed(0) + 'k';
                    }
                }
            },
            axisX: {
                type: Chartist.FixedScaleAxis,
                divisor: this.xFrequency == 'years' ? 7 : 3,
                labelInterpolationFnc: (value) => {
                    if(this.xFrequency == 'years'){
                        return moment(value).format('YYYY');
                    }
                    if(this.xFrequency == 'month'){
                        return moment(value).format('DD MMM YY');
                    }
                    return false;
                }
            }
        }
        this.chartist = new Chartist.Line('#' + this.chartId, data, options);
        
        // this.chartist.on('draw', function(data) {
        //     if(data.type === 'line' || data.type === 'area') {
        //         data.element.animate({
        //             d: {
        //                 begin: 200 * data.index,
        //                 dur: 200,
        //                 from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
        //                 to: data.path.clone().stringify(),
        //                     easing: Chartist.Svg.Easing.easeOutQuint
        //             }
        //         });
        //     }
        // });
        
    }
}

