

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModal extends Vue {
    
    @Prop({
        required: true
    }) text: string;

    @Prop({
        required: true
    }) value: any;

    internal: string = "";
    created(){
        this.internal = this.value;
    }

    @Prop({
        required: true
    }) callback: (val) => void;
    
    sendValue(ev){
        if(ev.target.value)
            this.callback(ev.target.value);
        else
            this.callback(0);
    }

    close(){
        this.$emit('close');
    }

}
