




















import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import TemaCintura from '@/components/temaCintura.vue';
import ProgressBar from '@/components/progressBar.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient, TemaClient } from '@/services/Services';

@Component({
    components: {
        TemaCintura,
        ProgressBar
    }
})
export default class allTemi extends Vue {

    username: string = store.state.loginResponse.nomeUtente;
    
    temas: OM.TemaVm[] = [];

    beforeRouteEnter(to, from, next){
        TemaClient.getTemaVmListByPercorso(to.params.percorsoIdentifier)
        .then(x => {
            next((vc: allTemi) => {
                vc.temas = x;
            })
        })
    }

    navTo(tema: OM.Tema){
        // if(cintura.enabled){
            this.$router.push('/percorsi/' + this.$route.params.percorsoIdentifier + '/allTemi/' + tema.identifier);
        // }
    }

}

