


























import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/model';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import { GlossarioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent
    }
})
export default class Glossario extends Vue {

    definizione: Array<OM.GlossarioGroupVm> = new Array<OM.GlossarioGroupVm>();
    loaded: boolean = false;

    created(){
        GlossarioClient.getAll()
        .then(x => {
            this.definizione = x;
            this.loaded = true;
        })
    }
}

