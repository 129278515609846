import { StorageServices } from '@/services/StorageServices'
import { RegisterPush } from './Firebase/FirebasePushServices';
import { UserClient } from './Services';


class RegistrationResponse {
    registrationId: string;
    registrationType: string;
}

class _PushServices {
    Register(notificationCallback: (payload: any) => void){

        RegisterPush(notificationCallback);

    }
}


export let PushServices = new _PushServices();
