







import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TWEEN from '@tweenjs/tween.js';
import BuyAssetModal from './buyAssetModal.vue';

@Component
export default class AnimatedNumber extends Vue {

    @Prop() value: any;
    printValue: any = 0;

    @Prop({
        type: Number,
        required: false,
        default: 500
    }) speed: number;
    
    @Prop({
        type: Number,
        required: false,
        default: 0
    }) decimals: number;
    
    @Prop({
        type: Number,
        required: false,
        default: 0
    }) startFrom: number;

    @Prop({
        type: Boolean,
        required: false,
        default: true
    }) delayed: boolean;

    lastValue: number;
    timeoutId: number;

    @Watch('value')
    onValueChange(next, prev){
        if(this.delayed){
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => this.changeFunction(next), 100)
        } else {
            this.changeFunction(next);
        }
    }

    changeFunction(val){
        this.tween(this.lastValue, val);
        this.lastValue = val;
    }

    mounted(){
        this.tween(this.startFrom, this.value);
        this.lastValue = this.value;
    }

    tween(startValue, endValue){
        new TWEEN.Tween({ val: startValue })
            .to({ val: endValue }, this.speed)
            .onUpdate((ev) => {
                this.printValue = ev.val.toFixed(this.decimals);
            })
            .start();

        this.animate();
    }

    animate(){
        if (TWEEN.update()){
            requestAnimationFrame(this.animate)
        }
    }

}
