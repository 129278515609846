

























import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { CinturaClient } from '@/services/Services';

@Component({})
export default class PercorsiView extends Vue {

}

