
































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import Chartist from 'chartist/dist/chartist.min.js';
import store from '@/store';
import moment from 'moment';

@Component
export default class HorizontalChartView extends Vue {
    
    chartWidth: number = 5000;
    chartContainer: any;
    chartElement: any;
    chartItems: OM.DateValue[] = [];
    chartId: string = 'c' + (Math.random() * 10000000).toFixed(0);
    summaryChartId: string = 'sum' + (Math.random() * 10000000).toFixed(0);
    chartist: any;
    chartistSummary: any;
    xFrequency: string = 'month';
    yType: string = '%';
    bloccoOver: any;

    localValues: any[] = [];

    beforeRouteEnter(to, from, next){
        store.state.headerText = `<small class="text-orange small">` + 
            to.params.chartLabel + `</small>`; //<p class="mb-0">` + to.params.microClass + `</p>`;
        next();
    }
    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        if(window.cordova)
            (<any>screen).orientation.lock('portrait');
        next();
    }

    mounted(){
        this.yType = this.$route.params.yType == 'perc' ? '%' : this.$route.params.yType;
        this.chartElement = this.$refs.chartElement;
        this.chartContainer = this.$refs.chartContainer;
        this.bloccoOver = this.$refs.bloccoOver;

        if(window.cordova)
            (<any>screen).orientation.lock('landscape');

        setTimeout( () => {
            this.localValues = StorageServices.getChartData();
            this.plotChart();
            setTimeout(() => {
                this.chartContainer.scrollLeft = this.chartContainer.scrollWidth;
            }, 100);
        }, 100);
    }

    zoomOut(ev){
        if(this.chartWidth == 2000)
            return;
        this.chartWidth -= 500;
        this.chartElement.style.width = this.chartWidth + "px";
        
        if(this.chartWidth < 3000)
            this.chartOptions.axisX.divisor = 30
        else if(this.chartWidth < 3500)
            this.chartOptions.axisX.divisor = 40
        else if(this.chartWidth < 4500)
            this.chartOptions.axisX.divisor = 50
        else if(this.chartWidth < 5500)
            this.chartOptions.axisX.divisor = 60
        else if(this.chartWidth < 6500)
            this.chartOptions.axisX.divisor = 70
        else if(this.chartWidth < 7500)
            this.chartOptions.axisX.divisor = 80
        else if(this.chartWidth < 8500)
            this.chartOptions.axisX.divisor = 90
        else if(this.chartWidth < 9500)
            this.chartOptions.axisX.divisor = 100
        else
            this.chartOptions.axisX.divisor = 110
        

        this.chartScrollFunction(null);
        //this.chartist.update();
    }

    zoomIn(ev){
        if(this.chartWidth == 10000)
            return;
        this.chartWidth += 500;
        this.chartElement.style.width = this.chartWidth + "px";
        
        if(this.chartWidth < 3000)
            this.chartOptions.axisX.divisor = 30
        else if(this.chartWidth < 3500)
            this.chartOptions.axisX.divisor = 40
        else if(this.chartWidth < 4500)
            this.chartOptions.axisX.divisor = 50
        else if(this.chartWidth < 5500)
            this.chartOptions.axisX.divisor = 60
        else if(this.chartWidth < 6500)
            this.chartOptions.axisX.divisor = 70
        else if(this.chartWidth < 7500)
            this.chartOptions.axisX.divisor = 80
        else if(this.chartWidth < 8500)
            this.chartOptions.axisX.divisor = 90
        else if(this.chartWidth < 9500)
            this.chartOptions.axisX.divisor = 100
        else
            this.chartOptions.axisX.divisor = 110

        this.chartScrollFunction(null);
        //this.chartist.update();
    }

    chartOptions: any;
    plotChart(){
        this.localValues.splice(this.localValues.length - 1, 1);
        this.localValues.forEach(x => {
            //x.date = x.date.split('T')[0].substring(2);
            x.date = <any>new Date(x.date);
            if(this.yType == '%')
                x.value = x.value * 100;
        });
        //let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dic"];
        let months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
        let lastYear = null;
        let lastMonth = null;
        let daysCounter = 0;
        // let labels = this.localValues.map(x => {
        //     return x.date;
        // });

        let valueSerie = this.localValues.map(x => x.value);
        let xySerie = this.localValues.map(x => {
            return {
                y: x.value,
                x: x.date
            }
        });
        let data = {
            //labels: labels,
            series: [
                {
                    data: xySerie
                }
            ]
        };
        let dataSummary = {
            series: [
                {
                    data: valueSerie
                }
            ]
        };
        let max = Math.max(...valueSerie);
        let min = Math.min(...valueSerie);
        this.chartOptions = {
            lineSmooth: Chartist.Interpolation.none({
                fillHoles: false
            }),
            showPoint: false,
            showArea: true,
            high: max,
            low: min,
            axisY: {
                labelInterpolationFnc: (value) => {
                    if(this.yType == '%') {
                        return value.toFixed(1) + '%';
                    } else {
                        if(value < 1000)
                            return value;
                        return (value / 1000).toFixed(0) + 'k';
                    }
                }
            },
            axisX: {
                type: Chartist.FixedScaleAxis,
                divisor: 60,
                labelInterpolationFnc: (value) => {
                    if(this.xFrequency == 'years'){
                        return moment(value).format('YYYY');
                    }
                    if(this.xFrequency == 'month'){
                        return moment(value).format('DD MMM YY');
                    }
                    return false;
                }
            }
        }
        var optionsSummary = {
            lineSmooth: Chartist.Interpolation.none({
                fillHoles: false
            }),
            width: '100%',
            height: '60px',
            showPoint: false,
            showArea: false,
            high: max,
            low: min,
            axisY: {
                showGrid: false,
                showLabel: false,
                offset: 0
            },
            axisX: {
                showGrid: false,
                showLabel: false,
                offset: 0
            }
        }
        this.chartistSummary = new Chartist.Line('#' + this.summaryChartId, dataSummary, optionsSummary);
        
        setTimeout(() => {
            this.chartist = new Chartist.Line('#' + this.chartId, data, this.chartOptions);
            setTimeout(() => {
                let vLabels = document.querySelectorAll('.chartContainer .ct-label.ct-vertical');
                for(let i = vLabels.length-1; i >= 0; i--){
                    (<any>vLabels[i]).style.width = "100%";
                    (<any>vLabels[i]).style.paddingRight = "4px";
                    (<any>this.$refs.labelsContainer).append(vLabels[i]);
                }
                this.chartElement.style.marginLeft = "-48px";
            }, 100);
        }, 0);
    }
    
    bloccoOverTimeout: any;
    scrollTimeout: any;
    chartScrollFunction(ev){
        let sinistra = this.chartContainer.scrollLeft * 100 / this.chartContainer.scrollWidth;
        let destra = (this.chartContainer.scrollLeft + window.innerWidth * 0.9) * 100 / this.chartContainer.scrollWidth;
        
        clearTimeout(this.bloccoOverTimeout);
        this.bloccoOverTimeout = setTimeout(() => {
            this.bloccoOver.style.left = sinistra + "%";
            this.bloccoOver.style.width = (destra - sinistra) + '%';
        }, 0);

        //this.updateChartValuesByPercentages();
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(this.updateChartValuesByPercentages, 100);
    }

    onSummaryTouch(ev){
        let halfBloccoWidth = parseFloat(getComputedStyle(this.bloccoOver).width) / 2;
        let newLeft: number;
        if(ev.touches){
            newLeft = (ev.touches[0].clientX - halfBloccoWidth);
        } else {
            newLeft = ev.clientX - halfBloccoWidth;
        }
        if(newLeft < 0)
            newLeft = 0;
        if(newLeft + halfBloccoWidth * 2 > window.innerWidth)
            newLeft = window.innerWidth -  halfBloccoWidth * 2;
        this.bloccoOver.style.left = newLeft + 'px';
        let newLeftPercentage = (newLeft * 100) / window.innerWidth;
        this.chartContainer.scrollLeft = (this.chartContainer.scrollWidth * newLeftPercentage) / 100;
        //this.updateChartValuesByPercentages();
    }

    updateChartValuesByPercentages(){
        let sinistra = this.chartContainer.scrollLeft * 100 / this.chartContainer.scrollWidth;
        let destra = (this.chartContainer.scrollLeft + window.innerWidth * 0.9) * 100 / this.chartContainer.scrollWidth;
        let daDoveParto = Math.floor(this.localValues.length * sinistra / 100);
        let doveArrivo = Math.ceil(this.localValues.length * destra / 100)
        let partialValues = this.localValues.slice(daDoveParto, doveArrivo);
        let partialValuesValues = partialValues.map(x => x.value);
        let max = Math.max(...partialValuesValues);
        let min = Math.min(...partialValuesValues);
        this.chartOptions.high = max;
        this.chartOptions.low = min;
        this.chartist.update(null, this.chartOptions);
        this.$nextTick(() => {
            (<any>this.$refs.labelsContainer).innerHTML = "";
            let vLabels = document.querySelectorAll('.chartContainer .ct-label.ct-vertical');
            for(let i = vLabels.length-1; i >= 0; i--){
                (<any>vLabels[i]).style.width = "100%";
                (<any>vLabels[i]).style.paddingRight = "4px";
                (<any>this.$refs.labelsContainer).append(vLabels[i]);
            }
        })
    }

}
