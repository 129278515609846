












import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeDiacritics } from '@/utility';

@Component
export default class IconTitleTextCard extends Vue {

    @Prop() label: string;
    @Prop() label2: string;
    @Prop() text: string;

    get imgName(){
        let ris = removeDiacritics(this.label).toLowerCase();
        return ris.replace(/ /g,"_");
    }

}
