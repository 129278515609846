




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class PhotoModal extends Vue {
    
    @Prop() text: string;
    @Prop({
        type: [Function]
    }) openCamera: any;
    @Prop({
        type: [Function]
    }) openGallery: any;

    getFromCamera(){
        this.openCamera();
        setTimeout(()=>{
            this.$emit('close');
        }, 250);    
    }

    getFromGallery(){
        this.openGallery();
        setTimeout(()=>{
            this.$emit('close');
        }, 250);
    }

}
