

































































import router from '@/router';
import { Vue, Watch } from 'vue-property-decorator';
import Component from '@/routeComponent'
import { StorageServices } from '@/services/StorageServices'
import { ModalServices } from '@/services/ModalServices'
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import AlertModal from '@/components/alertModal.vue'
import { FriendChallengeClient } from '@/services/Services';

@Component({
    components: {
        AlertModal
    }
})
export default class ManageFriendChallenges extends Vue {

    challenges: VM.FriendChallengeVm[] = [];
    section: string = 'gare';
    receivedInvites: OM.FriendChallengeInvite[] = [];

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<small class="text-orange small">` + challenge.titolo + `</small><p class="mb-0">` 
            + 'Gare con gli amici' + `</p>`;
        FriendChallengeClient.getByUser(store.state.loginResponse.userIdentifier, challenge.identifier)
        .then(x => {
            next((vc: ManageFriendChallenges) => {
                vc.challenges = x;
            });
        })
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    createChallenge(){
        this.$router.push('createFriendChallenge');
    }

    rankingChallenge(item: VM.FriendChallengeVm){
        StorageServices.setSelectedFriendChallenge(item);
        this.$router.push('friendChallengeRanking/' + item.identifier);
    }

    setInviti(){
        this.section = 'inviti';
        if(this.receivedInvites.length == 0){
            let challenge = StorageServices.getSelectedChallenge();
            FriendChallengeClient.getReceivedInvites(store.state.loginResponse.userIdentifier, challenge.identifier)
            .then(x => {
                this.receivedInvites = x;
            });
        }
    }

    rifiutaInvito(item: OM.FriendChallengeInvite, index: number){
        FriendChallengeClient.denyInvite(item.identifier)
        .then(x => {
            this.receivedInvites.splice(index, 1);
            ModalServices.OpenModal(AlertModal, {
                text: 'Invito rifiutato!'
            })
        })
    }

    accettaInvito(item: OM.FriendChallengeInvite, index: number){
        FriendChallengeClient.acceptInvite(item.identifier)
        .then(x => {
            this.receivedInvites.splice(index, 1);
            ModalServices.OpenModal(AlertModal, {
                text: 'Invito Accettato!'
            }, () => {
                let challenge = StorageServices.getSelectedChallenge();
                FriendChallengeClient.getByUser(store.state.loginResponse.userIdentifier, challenge.identifier)
                .then(x => {
                    this.challenges = x;
                })
            })
        })
    }
}

