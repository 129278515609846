



























import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/model';
import PageHeader from '@/components/pageHeader.vue';
import LezioneContent from '@/components/lezioneContent.vue';
import { GlossarioClient } from '@/services/Services';

@Component({
    components: {
        PageHeader,
        LezioneContent
    }
})
export default class Glossario extends Vue {

    classChangeDimension: string = "dimension_1";
    changeDimensionStepNumber: number = 1;
    definizione: OM.Glossario = new OM.Glossario();
    loaded: boolean = false;

    created(){
        GlossarioClient.getByKey(this.$route.params.word)
        .then(x => {
            this.definizione = x;
            this.loaded = true;
        })
    }

    minusText(){
        if(this.changeDimensionStepNumber > 1) {
            this.changeDimensionStepNumber -= 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }

    }

    plusText(){
        if(this.changeDimensionStepNumber < 4) {
            this.changeDimensionStepNumber += 1;
            this.classChangeDimension = "dimension_" + this.changeDimensionStepNumber;
        }
    }
}

