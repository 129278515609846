








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CallToButton extends Vue {

    @Prop({
        default: false,
        required: false
    }) relative: boolean;

}
