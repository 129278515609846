













































import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import PhotoModal from './photoModal.vue';
import FileInput from '@/components/fileInput.vue';
import InfoModal from '@/components/infoModal.vue';
import { StorageServices } from '@/services/StorageServices';
import { ModalServices } from '@/services/ModalServices';
import { FileServices } from '@/services/FileServices';
import { AuthServices } from '@/services/AuthServices';
import { appVersion, baseUrl } from '@/config';
import { CommonServices } from '@/services/CommonServices';
import { UserClient } from '@/services/Services';

@Component({
    components: {
        FileInput
    }
})
export default class ProfileEditView extends Vue {
    loaded: boolean = false;
    userVm: OM.ProfileVm = new OM.ProfileVm();
    appVersion: string = appVersion;

    isCordova: boolean = true;

    beforeRouteEnter(to, from, next){
        let proms = [];
        UserClient.getProfile()
        .then(x => {
            next((vc: ProfileEditView) => {
                vc.userVm = x;
                vc.loaded = true;
            });
        })
    }

    created() {
        if(!window.cordova){
            this.isCordova = false;
        }
    }

    saveProfile(){
        UserClient.saveProfile(this.userVm)
    }

    showPhotoModal(){
        ModalServices.OpenModal(PhotoModal,
        {
            openCamera: this.openCamera,
            openGallery: this.openGallery
        });
    }

    openInfoModal(){
        ModalServices.OpenModal(InfoModal,
        {
            vm: this.userVm,
        });
    }

    openCamera(){
        this.takePicture(1);
    }

    openGallery(){
        this.takePicture(0);
    }

    takePicture(from: number){
        let onSuccess = (imgData: any) => {
            store.commit('showSpinner');
            FileServices.UploadCordovaImage(imgData, store.state.loginResponse.userIdentifier)
            .then( x => {
                let url = decodeURI(x.response.replace(/"/g, ''));
                store.commit('hideSpinner');
                store.state.loginResponse.userImgUrl = url + "?cache=" + new Date().getTime().toString();
                let loginResponse = StorageServices.getLoginResponse();
                loginResponse.userImgUrl = store.state.loginResponse.userImgUrl;
                StorageServices.setLoginResponse(loginResponse);
            })
            .catch( err => {
                store.commit('hideSpinner');
            });
        }
        let onError = (err: any) => {
            store.commit('hideSpinner');
        }
        if(window.cordova){
            (<any>window).navigator.camera.getPicture(onSuccess, onError, {
                destinationType: 1,
                sourceType: from,
                correctOrientation: true,
                cameraDirection: 1
            })
        }
    }

    loadDesktopPicture(file: File) {

        var url = baseUrl + "api/User/UpdateProfileImage";
        CommonServices.uploadFileToUrl(url, file, {})
        .then(x => {
            let url: string;
            if(x.response){
                url = decodeURI(x.response.replace(/"/g, ''));
            } else {
                url = x;
            }
            store.state.loginResponse.userImgUrl = url + "?cache=" + new Date().getTime().toString();
            let loginResponse = StorageServices.getLoginResponse();
            loginResponse.userImgUrl = store.state.loginResponse.userImgUrl;
            StorageServices.setLoginResponse(loginResponse);
        });
    }

    logout(){
        AuthServices.logout()
        .then(x => {
            this.$router.replace('/login');
        })
    }
}

