import { render, staticRenderFns } from "./walletBar.vue?vue&type=template&id=240bb129&scoped=true&"
import script from "./walletBar.vue?vue&type=script&lang=ts&"
export * from "./walletBar.vue?vue&type=script&lang=ts&"
import style0 from "./walletBar.vue?vue&type=style&index=0&id=240bb129&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "240bb129",
  null
  
)

export default component.exports