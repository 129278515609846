










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import moment from 'moment';

@Component
export default class DrawdownCard extends Vue {

    @Prop({
        type: [ Object, OM.Drawdown ]
    }) drawdown: OM.Drawdown;


    startedLabel: string = "-";
    minRecuperoLabel: string = "-";
    durataDrawdownLabel: string = "-";

    startedMinDays: any = '-';
    minRecuperoDays: any = '-';
    durataDrawdownDays: any = '-';

    created(){
        let diff = Math.abs(new Date(this.drawdown.dataInizioDrawdown).getTime() - new Date(this.drawdown.prezzoMin.date).getTime());
        let ris = parseFloat((diff / 1000 / 60 / 60 / 24).toFixed(0));
        if(ris < 365){
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
            this.startedMinDays = ris;
        } else if(ris < 1080) {
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
            this.startedMinDays = Math.round(ris / 30);
        } else {
            this.startedLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
            this.startedMinDays = parseFloat((ris / 365).toFixed(1));
        }

        if(this.drawdown.dataFineDrawdown){
            diff = Math.abs(new Date(this.drawdown.prezzoMin.date).getTime() - new Date(this.drawdown.dataFineDrawdown).getTime());
            ris = parseFloat((diff / 1000 / 60 / 60 / 24).toFixed(0));
            if(ris < 365){
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
                this.minRecuperoDays = ris;
            } else if(ris < 1080) {
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
                this.minRecuperoDays = Math.round(ris / 30);
            } else {
                this.minRecuperoLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
                this.minRecuperoDays = parseFloat((ris / 365).toFixed(1));
            }
        }

        this.drawdown.durataDrawdown;
        if(this.drawdown.durataDrawdown < 365){
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_days;
            this.durataDrawdownDays = this.drawdown.durataDrawdown;
        } else if(this.drawdown.durataDrawdown < 1080) {
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_months;
            this.durataDrawdownDays = Math.round(this.drawdown.durataDrawdown / 30);
        } else {
            this.durataDrawdownLabel = this.$store.state.dizionario.challenge_assets_chart_drawdown_years;
            this.durataDrawdownDays = parseFloat((this.drawdown.durataDrawdown / 365).toFixed(1));
        }
    }

    dataFine(){
        if(this.drawdown.dataFineDrawdown)
            return moment(this.drawdown.dataFineDrawdown).format('DD MMM YY');
        else
            return '-';
    }
}

