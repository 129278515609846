















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import AnimatedNumber from '@/components/animatedNumber.vue'

@Component({
    components: {
        AnimatedNumber
    }
})
export default class ProgressBar extends Vue {
    
    @Prop() completed: number;
    @Prop() color: string;
    
    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) animate: boolean;

    localCompleted: number = 0;
    moving: boolean = false

    movingTimeout: number;
    @Watch('completed')
    onCompletedChange(next, prev){
        this.localCompleted = next;
        if(this.animate){
            this.moving = true;
            clearTimeout(this.movingTimeout);
            setTimeout(() => {
                this.moving = false;
            }, 3000);
        }
    }

    created(){
        if(this.animate){
            this.localCompleted = 0;
            setTimeout(() => {
                this.localCompleted = this.completed || 0;
            }, 0)
        } else {
            this.localCompleted = this.completed || 0;
        }
    }


}
