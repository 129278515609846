

















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Component
export default class PeriodiCard extends Vue {

    @Prop({
        type: [ Array ]
    }) periodi: OM.DatiPeriodo[];

    selectedPeriodo = null;
    get periodo(){
        return this.selectedPeriodo || this.periodi[0];
    }
    set periodo(val){
        this.selectedPeriodo = val;
    }
}

