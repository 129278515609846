


















import { Component, Vue } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices'

@Component
export default class App extends Vue {

    created(){
        ModalServices.SetModalInstance(this.$opModal);
    }

    get showSpinner(){
        return this.$store.state.showSpinner > 0;
    }
}
