














































































































































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import SwiperSlides from '@/components/swiperSlides.vue';
import ChartCard from '@/components/chartCard.vue';
import * as Enum from '@/enum';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import AnalysisSlide from './analysisSlide.vue';
import AssetTypeSlugs from '@/components/assetTypeSlugs.vue';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        SwiperSlides,
        ChartCard,
        AssetTypeIcon,
        AnalysisSlide,
        AssetTypeSlugs
    }
})
export default class AnalysisView extends Vue {

    resoconti: OM.KeyValuePairOfStringAndResocontoPortafoglio[] = [];
    filter: OM.AssetFilterVm = new OM.AssetFilterVm();
    assets: OM.KeyValuePairOfStringAndResocontoPortafoglio[] = [];
    selectedAsset: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoLiquidity: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoWallet: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;
    resocontoProperty: string = 'performance';
    resocontoPropertyType: string = '%';
    orangeTop: boolean = true;
    slideIndex: number = 0;

    periodFilters: string[] = [];

    chartData: OM.ResocontoPortafoglio = null;

    beforeRouteEnter(to, from, next){
        let challenge = StorageServices.getSelectedChallenge();
        store.state.headerText = `<p class="mb-0">` + challenge.titolo + `</p>`;
        next();
    }

    beforeRouteLeave(to, from, next){
        store.state.headerText = '';
        next();
    }

    created(){
        this.filter.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        this.filter.assetType = this.$route.params.assetType || '';
        this.filter.macroClass = this.$route.params.macroClass || '';
        this.filter.microClass = this.$route.params.microClass || '';

        this.resocontoProperty = store.state.analysisProperty || 'performance';
        this.resocontoPropertyType = store.state.analysisPropertyType || '%';
        if(this.resocontoProperty == "performance"){
            this.slideIndex = 0;
        } else if(this.resocontoProperty == "controValore") {
            this.slideIndex = 1;
        } else if(this.resocontoProperty == "utiliPerdite"){
            this.slideIndex = 2;
        } else if(this.resocontoProperty == "peso"){
            this.slideIndex = 3;
        } else if(this.resocontoProperty == "volatilita20"){
            this.slideIndex = 4;
        } else if(this.resocontoProperty == "commissioni"){
            this.slideIndex = 5;
        } else if(this.resocontoProperty == "commissioniPerc"){
            this.slideIndex = 6;
        }
        
        this.applyFilter()
        .then(x => {
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_fromStart']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_quarterToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_monthToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_weekToDate']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastWeek']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastMonth']);
            this.periodFilters.push(store.state.dizionario['analysis_period_filter_lastQuarter']);
        });
    }

    applyFilter(){
        let proms = [];
        proms.push(PortafoglioClient.getAnalysis(this.filter));
        if(this.filter.assetType != "" && this.filter.macroClass != "" && this.filter.microClass != ""){
            proms.push(PortafoglioClient.getAssetsForAnalysis(this.filter));
        }
        return Promise.all(proms)
        .then(data => {
            this.resoconti = data[0];
            if(this.filter.assetType == "" && this.filter.macroClass == "" && this.filter.microClass == ""){
                this.orangeTop = false;
                this.resocontoWallet = this.resoconti[0];
                StorageServices.setResocontoWallet(this.resocontoWallet);
                this.chartData = this.resocontoWallet.value;
            } else {
                this.chartData = this.resoconti[0].value;
            }
            this.resocontoWallet = StorageServices.getResocontoWallet();
            if(data[1])
                this.assets = data[1];
        })
    }

    setPeriodFilter(input){
        if(input == store.state.dizionario['analysis_period_filter_fromStart']){
            this.filter.resocontoType = "";
        } else if(input == store.state.dizionario['analysis_period_filter_quarterToDate']){
            this.filter.resocontoType = Enum.ResocontoType.QuarterToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_monthToDate']){
            this.filter.resocontoType = Enum.ResocontoType.MonthToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_weekToDate']){
            this.filter.resocontoType = Enum.ResocontoType.WeekToDate;
        } else if(input == store.state.dizionario['analysis_period_filter_lastWeek']){
            this.filter.resocontoType = Enum.ResocontoType.LastWeek;
        } else if(input == store.state.dizionario['analysis_period_filter_lastMonth']){
            this.filter.resocontoType = Enum.ResocontoType.LastMonth;
        } else if(input == store.state.dizionario['analysis_period_filter_lastQuarter']){
            this.filter.resocontoType = Enum.ResocontoType.LastQuarter;
        }
        this.applyFilter();
    }

    clickFunction(val, i){
        if(val == 'Portafoglio')
            return;
        if(i == 0 && this.orangeTop)
            this.$router.back();
        else
            this.addToFilter(val);
    }

    addToFilter(value){
        if(this.filter.assetType == ''){
            this.$router.push('analysis/' + value);
        } else if(this.filter.macroClass == '') {
            this.$router.push(this.filter.assetType + '/' + value);
        } else if(this.filter.microClass == '') {
            this.$router.push(this.filter.macroClass + '/' + value);
        } else {
            return;
        }
    }

    selectAsset(item: OM.KeyValuePairOfStringAndResocontoPortafoglio){
        this.selectedAsset = item;
    }

    onSlideChange(index){
        if(index == 0){
            this.resocontoProperty = "performance";
            this.resocontoPropertyType = '%';
        } else if(index == 1) {
            this.resocontoProperty = "controValore";
            this.resocontoPropertyType = 'k';
        } else if(index == 2){
            this.resocontoProperty = "utiliPerdite";
            this.resocontoPropertyType = 'k';
        } else if(index == 3){
            this.resocontoProperty = "peso";
            this.resocontoPropertyType = '%';
        } else if(index == 4){
            this.resocontoProperty = "volatilita20";
            this.resocontoPropertyType = '%';
        } else if(index == 5){
            this.resocontoProperty = "commissioni";
            this.resocontoPropertyType = 'k';
        } else if(index == 6){
            this.resocontoProperty = "commissioniPerc";
            this.resocontoPropertyType = '%';
        }
        store.state.analysisProperty = this.resocontoProperty;
        store.state.analysisPropertyType = this.resocontoPropertyType;
    }

    openChart(data, label, yType){
        label = label.replace('%', 'perc');
        StorageServices.setChartData(data);
        let title = "";
        if(this.filter.microClass != '')
            title = this.filter.microClass;
        else if(this.filter.macroClass != '')
            title = this.filter.macroClass;
        else if(this.filter.assetType != '')
            title = this.filter.assetType;
        else
            title = this.$store.state.dizionario.analysis_label_totale_portafoglio;
        this.$router.push('/horizontalChart/' + label + '/' + title + '/' + yType);
    }
}

