


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModal extends Vue {
    
    @Prop({
        required: true
    }) title: string;

    @Prop({
        required: true
    }) text: string;
    
    @Prop({
        required: true
    }) callback: any;
    
    @Prop({
        required: false,
        default: true
    }) showCancel: boolean;

    @Prop({
        required: false,
        default: ''
    }) cancelText: string;
    
    @Prop({
        required: false,
        default: ''
    }) confirmText: string;
    
    confirmFunction(){
        this.callback(this);
        this.$emit('close');
    }
}
