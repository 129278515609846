













import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { ModalServices } from '@/services/ModalServices';
import FullImageModal from '@/components/fullImageModal.vue';

@Component
export default class LezioneContent extends Vue {
    
    @Prop({
        type: [OM.LezioneContent, Object]
    }) content: OM.LezioneContent;

    container: any;
    mounted(){
        this.container = this.$refs.contentContainer;
        let imgs = this.container.querySelectorAll('img');
        imgs.forEach(x => {
            x.addEventListener('click', (ev) => {
                if(!window.cordova)
                    return;

                PhotoViewer.show(ev.target.src, '');
            });
        })
    }

}
