
















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import moment from 'moment';

@Component
export default class ChatMessage extends Vue {
    userId: string = store.state.loginResponse.userIdentifier;
    
    @Prop({
        type: [Object, OM.ChatMessage]
    }) message: OM.ChatMessage;

    @Prop() senderImage: string;

    timer: any = null;
    checkLongPress(){
        var touchduration = 400;
        this.timer = setTimeout(() => {
            this.$emit('longtouch', this.message);
        }, touchduration);
    }
    voidLongPress(){
        clearTimeout(this.timer);
    }
    emitLongTouch(){
        this.$emit('longtouch', this.message);
    }
}
