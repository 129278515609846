









import { Vue, Component, Prop } from 'vue-property-decorator';
import store from '@/store';
import * as VM from '@/viewModel';
import * as OM from '@/model';
import * as Enum from '@/enum';
import RankingCard from '@/components/rankingCard.vue';

@Component({
    components: {
        RankingCard
    }
})
export default class RankingCardSelector extends Vue {

    @Prop() cards: VM.RankingCardVm[];
    @Prop() selected: OM.ValueIdentifierOfString[];

    created(){
        this.cards.forEach(x => {
            if(this.selected.findIndex(c => c.identifier == x.userIdentifier) > -1){
                Vue.set(x, 'selected', true);
                Vue.set(x, 'blocked', x.blocked);
            }
        })
    }

    select(item: VM.RankingCardVm){
        if(item.userIdentifier == Enum.BenchmarkIdentifier || item.isSelf || item.blocked)
            return;

        let ind = this.selected.findIndex(x => x.identifier == item.userIdentifier);
        if(ind > -1 && (<any>this.selected[ind]).blocked){
            return;
        }

        if(ind > -1){
            Vue.set(item, 'selected', false);
            this.selected.splice(ind, 1);
        } else {
            Vue.set(item, 'selected', true);
            this.selected.push({
                identifier: item.userIdentifier,
                value: item.playerName
            });
        }
    }

}

